import { useEffect, useState, useCallback } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useDispatch, useSelector } from "react-redux";
import {
  dietChartSelector,
  clearClientState,
  clearState,
} from "store/features/dietchart";

import { toast } from "react-toastify";

import { userSelector } from "store/features/authSlice";
import ChartTable from "./sub-components/ChartTable";
import PaymentModal from "./sub-components/modal/payment";
import DietModal from "./sub-components/modal/diet";

import Card from "@mui/material/Card";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import NoData from "containers/DataNotAvailBox";
import { TailSpin } from "react-loader-spinner";
import DeleteModal from "containers/DeleteModal";
import Createable from "containers/Select";

import {
  getClientList,
  getClient,
  RemoveDiet,
  getClientDietChart,
} from "services/dietchart";
import CustomButton from "containers/CustomButton";
import { CLIENT_NOT_FOUND } from "constant";
import { useLocation } from "react-router-dom";
import { getClientKey } from "services/dietchart";

function DietChart() {
  const { uid } = useSelector(userSelector);

  const { isError, isSuccess, message, isFetching } =
    useSelector(dietChartSelector);

  const dispatch = useDispatch();

  //for deleting recipe
  const [deleteModal, setDeleteModal] = useState(false);

  const [newPaymentModal, setNewPaymentModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [newDietModal, setNewDietModal] = useState(false);

  //for getting client list
  const [clients, setClients] = useState([]);
  const [clientLoader, setClientLoader] = useState(false);

  //diet chart of client
  const [dietchart, setDietChart] = useState([]);
  const getClientListHandler = useCallback(async () => {
    try {
      setClientLoader(true);
      const data = await getClientList(uid);
      setClientLoader(false);
      setClients(data);
    } catch (err) {
      setClientLoader(false);
      toast.error("Something went wrong!!");
    }
  }, [uid]);

  useEffect(() => {
    getClientListHandler();
  }, [getClientListHandler]);

  const addHandler = async () => {
    resetHandler();
    setMode("create");
    setClient(null);
    setNewDietModal(true);
    setEditValue(false);
  };

  //for editing diet chartoaderv1
  const editHandler = async (item, type) => {
    setMode("edit");
    setSelectedItem(item);
    if (type === "payment") {
      return setNewPaymentModal(true);
    }
    setNewDietModal(true);
  };

  //delete handler
  const [deleteLoader, setDeleteLoader] = useState(false);
  const deleteHandler = async (id) => {
    try {
      setDeleteLoader(true);
      await RemoveDiet(id);

      setDietChart((e) => {
        e = e.filter((item) => {
          if (item.dietChartId !== id) {
            return item;
          }
          return null;
        });
        return [...e];
      });
      toast.success("Successfully deleted!!!");
      setDeleteModal(false);
      setSelectedItem(null);
      setDeleteLoader(false);
    } catch (e) {
      setDeleteLoader(false);
      toast.error(e.message);
    }
  };

  const [selectedClientv1, setSelectedClientv1] = useState();
  //use for search with unique code field
  const [client, setClient] = useState(null);
  const [getClientLoader, setGetClientLoader] = useState(false);
  const getSelectedClient = useCallback(async () => {
    try {
      if (selectedClientv1) {
        const data = await getClient(selectedClientv1.value);

        const dietchart_ = await getClientDietChart(selectedClientv1.value);
        setDietChart(dietchart_);

        setGetClientLoader(false);
        if (data === CLIENT_NOT_FOUND) {
          toast.info("Client not found!!!");
        } else {
          setClient(data);
        }
      } else {
        setGetClientLoader(false);
      }
    } catch (e) {
      setGetClientLoader(false);
      console.log(e);
      toast.info("Something went wrong!!!");
    }
  }, [selectedClientv1]);

  useEffect(() => {
    if (selectedClientv1) {
      getSelectedClient();
    }
  }, [getSelectedClient, selectedClientv1]);

  const resetHandler = () => {
    setSelectedClientv1(null);
    dispatch(clearClientState());
  };

  const [editValue, setEditValue] = useState(true);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);

      if (selectedItem) {
        getSelectedClient();
      }
      dispatch(clearState());
    }
    if (isError) {
      toast.error(message);
      dispatch(clearState());
    }
  }, [
    isSuccess,
    message,
    dispatch,
    isError,
    isFetching,
    selectedItem,
    getSelectedClient,
  ]);

  const location = useLocation();

  const fetchClientKey = useCallback(async (id) => {
    let data = await getClientKey({ id });
    setSelectedClientv1(data);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    if (id) {
      fetchClientKey(id);
    }
  }, [location, fetchClientKey]);

  const [mode, setMode] = useState("view");
  const viewHandler = useCallback(async (item, type) => {
    setMode("view");
    setSelectedItem(item);
    switch (type) {
      case "diet":
        setNewDietModal(true);
        break;
      case "payment":
        setNewPaymentModal(true);
        break;
      default:
        break;
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card style={{ minHeight: "28vh", overflow: "inherit" }}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={1}
        >
          <SuiTypography display="flex" alignItems="center">
            <SuiTypography variant="h6" ml={1}>
              {"Diet Chart List"}
            </SuiTypography>
            <span
              style={{
                paddingLeft: "6px",
                fontSize: "14px",
                height: "16px",
                width: "14px",
              }}
            >
              <Tooltip
                title={
                  "Choose your existing clients from the list below and view diet chart."
                }
                placement="top"
                style={{ color: "#67748e", height: "16px", width: "14px" }}
              >
                <Icon color="inherit">{"help"}</Icon>
              </Tooltip>
            </span>
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              width: "80%",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              color="dark"
              variant="gradient"
              onClick={addHandler}
              width={180}
              style={{
                marginBottom: 10,
                height: 40,
                width: 230,
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              title={"Add New Diet Plan"}
            />
          </SuiBox>
        </SuiBox>
        <SuiTypography
          variant="button"
          fontWeight="regular"
          color={"dark"}
          sx={{ ml: 3.5, mb: 2 }}
        >
          Choose a client from the dropdown below to view their Diet Chart.
        </SuiTypography>

        <SuiBox
          px={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Createable
            options={clients}
            loading={clientLoader}
            value={selectedClientv1}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 220,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose client"
            onChange={(e) => {
              setClient(null);
              setGetClientLoader(true);
              setSelectedClientv1(e);
            }}
          />

          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            onClick={resetHandler}
          >
            Reset
          </SuiButton>
        </SuiBox>

        {client !== null ? (
          <ChartTable
            viewHandler={viewHandler}
            setMode={setMode}
            newPaymentModal={newPaymentModal}
            dietchart={dietchart}
            dispatch={dispatch}
            dietChartArray={dietchart ?? []}
            selectedClient={client}
            setSelectedItem={setSelectedItem}
            setNewPaymentModal={setNewPaymentModal}
            editHandler={editHandler}
            isSuccess={isSuccess}
            deleteHandler={(id, name) => {
              setSelectedItem({ id, name });
              setDeleteModal(true);
            }}
          />
        ) : getClientLoader ? (
          <SuiBox
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            <TailSpin color="#17c1e8" height={60} width={60} />
          </SuiBox>
        ) : (
          <SuiBox style={{ marginTop: 35 }}>
            <NoData />
          </SuiBox>
        )}
      </Card>

      <PaymentModal
        open={newPaymentModal}
        mode={mode}
        setOpenModal={setNewPaymentModal}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        message={message}
        id={client?.id}
        uid={uid}
        services={client?.services ?? []}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handleCloseModal={() => {
          setTimeout(() => {
            if (selectedItem) {
              setSelectedItem(null);
            }
          }, 500);
          setNewPaymentModal(false);
        }}
        viewHandler={viewHandler}
        editHandler={editHandler}
      />

      <DietModal
        open={newDietModal}
        mode={mode}
        setOpenModal={setNewDietModal}
        setDietChart={setDietChart}
        id={uid}
        clients={clients}
        editValue={editValue}
        client={client}
        setSelectedClientv1={setSelectedClientv1}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handleCloseModal={() => {
          setEditValue(true);
          setNewDietModal(false);
        }}
        viewHandler={viewHandler}
        editHandler={editHandler}
      />

      {deleteModal && (
        <DeleteModal
          isFetching={deleteLoader}
          onDeleteHandler={deleteHandler}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
    </DashboardLayout>
  );
}

export default DietChart;
