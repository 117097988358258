import { useEffect, useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SuiTypography from "components/SuiTypography";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { userSelector } from "store/features/authSlice";
import { useParams } from "react-router-dom";
import { ClientSelector, deleteDocument } from "store/features/clients";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import DeleteModal from "containers/DeleteModal";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "firebaseConfig";
import { getClientFamilies } from "services/clientdetails";
import { getSelectedClient } from "services/clientdetails";
import { getServiceInventory } from "services/clientdetails";
import { getSelectedClientDetails } from "services/clientdetails";
import AccountHistory from "containers/clientDetails/admin/accounthistory";
// import DietLifestyleAssessmentTableCard from "containers/clientDetails/admin/dietLifestyleAssessment";
import PhysicalActivityTracker from "containers/clientDetails/admin/physicalActivityTracker";
import BiochemicalHealthRecords from "containers/clientDetails/admin/biochemical";
import VitalDataVisualizer from "containers/clientDetails/admin/vital";
import MeasurementDataVisualizer from "containers/clientDetails/admin/measurement";
import DietRecallSummary from "containers/clientDetails/admin/dietrecall";
import DailyIntakeForm from "containers/clientDetails/admin/dailyIntakeForm";
import ImageNowVsEarlier from "containers/clientDetails/admin/ImageNowVsEarlier";
import CustomDietPdf from "containers/clientDetails/admin/dietPdf";
import ProductPurchases from "containers/clientDetails/admin/productPurchases";
import Profile from "containers/clientDetails/admin/profile";
// import EditProfileModal from 'containers/clientDetails/admin/profile/edit-profile';
import PaymentModal from "containers/clientDetails/sub-components/modal/payment";
import SoldProductModal from "containers/clientDetails/sub-components/modal/product";
import EditProfileModal from "containers/clientDetails/sub-components/modal/profile";
import { clearState } from "store/features/clients";
import MyInvoiceDoc from "containers/pdf/billingPdf";
import { pdf } from "@react-pdf/renderer";
import { blobToBase64 } from "utils/common";
import { sendInvoicePdf } from "firebaseConfig";
import { invoiceBillingPdf } from "utils/pdf";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import CustomButton from "containers/CustomButton";
import { sendMultipleInvoicePdf } from "firebaseConfig";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <SuiBox sx={{ p: 3 }}>{children}</SuiBox>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ClientDetail() {
  let { id } = useParams();
  const {
    uid,
    phoneNumber,
    name,
    email,
    address,
    logo,
    qr,
    signature,
    instagram,
    fb,
    twitter,
    termsAndConditions,
    gstNumber,
  } = useSelector(userSelector);

  const user = {
    name,
    email,
    phoneNumber,
    address,
    logo,
    qr,
    signature,
    instagram,
    fb,
    twitter,
    gstNumber,
    termsAndConditions,
  };

  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  const { isError, isSuccess, message, isFetching } =
    useSelector(ClientSelector);

  const [selectedClient, setSelectedClient] = useState();
  const [services, setServices] = useState([]);
  const clientHandler = useCallback(async () => {
    try {
      setLoader(true);
      const servicesArray = await getServiceInventory({ uid });
      const data = await getSelectedClient({ id });
      const clientDetails = await getSelectedClientDetails({ id });
      setServices(servicesArray);
      setFormdata({ ...formdata, email: data.email });
      setSelectedClient({ ...data, ...clientDetails });
      setLoader(false);
    } catch (e) {
      setLoader(false);
      toast.error("Something went wrong, retry");
    }
  }, [id, uid]);

  useEffect(() => {
    if (id) {
      clientHandler();
    }
  }, [id, clientHandler]);

  //for dietary, physical, bichemical, measurement, vital array list
  // const [dietaryArray, setDietaryArray] = useState([]);
  const [physicalArray, setPhysicalArray] = useState([]);
  const [biochemicalArray, setBiochemicalArray] = useState([]);
  const [measurementArray, setMeasurementArray] = useState([]);
  const [vitalArray, setVitalArray] = useState([]);
  const [paymentArray, setPaymentArray] = useState([]);
  const [dietRecallArray, setDietRecallArray] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [dailyIntakeArray, setDailyIntakeArray] = useState([]);
  const [customDietPdf, setCustomDietPdf] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [invoiceArr, setInvoiceArr] = useState([]);
  const [formdata, setFormdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [isInvoiceModal, setIsInvoiceModal] = useState(false);

  useEffect(() => {
    if (selectedClient) {
      const {
        physical,
        biochemical,
        measurement,
        vital,
        services,
        dietRecall,
        products,
        imageNowVsEarlier,
        dailyIntake,
      } = selectedClient;

      // setDietaryArray(dietary ?? []);
      setPhysicalArray(physical ?? []);
      setBiochemicalArray(biochemical ?? []);
      setMeasurementArray(measurement ?? []);
      setVitalArray(vital ?? []);
      setPaymentArray(services ? [...services] : []);
      setProductArray(products ?? []);
      setDietRecallArray(dietRecall ?? []);
      setImageArray(imageNowVsEarlier ?? []);
      setDailyIntakeArray(dailyIntake ?? []);
    }
  }, [selectedClient, isSuccess]);

  useEffect(() => {
    setCustomDietPdf(selectedClient?.customDietPdf ?? []);
  }, [selectedClient?.customDietPdf]);

  //For Sorting of images date
  const [imageArray1, setImageArray1] = useState([]);
  useEffect(() => {
    let imageArrayv1 = imageArray.slice();

    imageArrayv1.sort(function (a, b) {
      return Date.parse(b.date) - Date.parse(a.date);
    });
    setImageArray1(imageArrayv1);
  }, [imageArray]);

  const [selectedItem, setSelectedItem] = useState(null); //for selecting product for allocation

  //for deleting product
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteHandler = (clientId, persona, id, selectedItemIndex) => {
    dispatch(deleteDocument({ persona, clientId, id, selectedItemIndex }));
  };

  const [newPaymentModal, setNewPaymentModal] = useState(false);

  const [editProfileModal, setEditProfileModal] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [newSellModal, setNewSellModal] = useState(false);

  const editProductHandler = async (item) => {
    try {
      //   setLoader(true);
      setMode("edit");
      const ref = doc(firestore, "product", item.productId);
      const snapshot = await getDoc(ref);
      const { availableQuantity } = snapshot.data();
      setSelectedItem({ ...item, availableQuantity });
      setNewSellModal(true);
      //   setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Something went wrong, retry");
    }
  };

  const [profileEditLoader, setProfileEditLoader] = useState(false);
  const [families, setFamilies] = useState([]);

  const editProfileHandler = async () => {
    try {
      setProfileEditLoader(true);
      const familyNames = await getClientFamilies({ uid });
      setFamilies(familyNames);
      setSelectedItem(selectedClient);
      setEditProfileModal(true);
      setProfileEditLoader(false);
    } catch (err) {
      setProfileEditLoader(false);
      toast.error("Something went wrong, retry!");
    }
  };

  const [mode, setMode] = useState("view");
  const viewHandler = useCallback(async (item, type) => {
    setMode("view");
    setSelectedItem(item);
    switch (type) {
      case "payment":
        setNewPaymentModal(true);
        break;
      case "product":
        setNewSellModal(true);
        break;
      default:
        break;
    }
  }, []);

  const onDeleteSuccess = useCallback(() => {
    if (deleteModal) {
      setDeleteModal(false);
    }
  }, [deleteModal]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      clientHandler();
      dispatch(clearState());
      onDeleteSuccess();
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }

    if (isFetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isSuccess, message, dispatch, isError, isFetching]);

  const mailCheckHandler = (selectedItem, indexOfItem) => {
    setProductArray((prevProductArray) => {
      const updatedArray = [...prevProductArray];
      updatedArray[indexOfItem] = {
        ...updatedArray[indexOfItem],
        checkBoxMail: !updatedArray[indexOfItem].checkBoxMail,
      };

      if (updatedArray[indexOfItem].checkBoxMail) {
        setInvoiceArr([...invoiceArr, { ...selectedItem }]);
      } else {
        const updatedInvoiceArr = invoiceArr.filter((item) => {
          if (item.id === selectedItem.id) {
            return false;
          } else {
            return true;
          }
        });
        setInvoiceArr(updatedInvoiceArr);
      }
      return updatedArray;
    });
  };

  const downloadAndStorePdf = async (billData) => {
    try {
      await invoiceBillingPdf({
        user,
        bill: billData.bill,
        termsAndConditions,
      });
    } catch (err) {
      throw err;
    }
  };

  const processInvoices = async () => {
    const invoices = await Promise.all(
      invoiceArr.map(async (invoice) => {
        const blob = await pdf(
          <MyInvoiceDoc
            user={user}
            bill={{ ...invoice }}
            note={user.termsAndConditions}
            type={formdata.type}
          />
        ).toBlob();

        const invoiceData = {
          invoicePdfBlob: await blobToBase64(blob),
        };

        await downloadAndStorePdf({
          bill: { ...invoice, clientId: invoice.id, ...formdata },
          user,
        });

        return invoiceData; // Return invoice data after all async operations
      })
    );

    await sendMultipleInvoicePdf({
      clientName: selectedClient.name,
      clientEmail: formdata.email,
      invoices,
      logo: user.logo,
      name: user.name,
    });
  };

  const onModalConfirmHandler = async () => {
    let numberRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let val = formdata.email;
    let match_ = val.match(numberRegex);
    if (!match_ && val) {
      return toast.error(`Please enter valid email!`);
    }
    if (
      formdata?.email === null ||
      formdata?.email === undefined ||
      formdata?.email === ""
    ) {
      toast.info(`Please enter email!`);
      return;
    }

    setErrorMessage({
      ...errorMessage,
      email: false,
    });

    setFormdata({ ...formdata, modal: false });
    setIsInvoiceLoading(true);
    setIsInvoiceModal(false);
    processInvoices()
      .then(() => {
        toast.info("Invoice sent & created successfully");
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      })
      .finally(() => {
        setIsInvoiceLoading(false);
        setProductArray(selectedClient.products);
        setInvoiceArr([]);
      });
  };

  const onModalCancelHandler = () => {
    setIsInvoiceModal(false);
  };

  return (
    <DashboardLayout>
      {isInvoiceModal && (
        <ConfirmationModal
          title="Enter the email address to send the invoices:"
          isInput
          inputName="email"
          formdata={formdata}
          setFormdata={setFormdata}
          onConfirmHandler={onModalConfirmHandler}
          onCancelHandler={onModalCancelHandler}
        />
      )}
      <DashboardNavbar
        status="client"
        title={
          selectedClient?.name ? selectedClient?.name + "'s Dashboard" : ""
        }
      />
      {loader ? (
        <SuiBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <TailSpin color="#17c1e8" height={80} width={80} />
        </SuiBox>
      ) : (
        <SuiBox py={3}>
          <SuiBox mb={3} px={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Profile
                  editHandler={editProfileHandler}
                  profileEditLoader={profileEditLoader}
                  selectedClient={selectedClient}
                  name={selectedClient?.name}
                  status={selectedClient?.status}
                  address={selectedClient?.address}
                  phoneNumber={selectedClient?.phoneNumber}
                  email={selectedClient?.email}
                  gender={selectedClient?.gender}
                  age={selectedClient?.age}
                  leadFrom={selectedClient?.leadFrom}
                  foodPreference={selectedClient?.preference}
                  familyName={selectedClient?.familyName}
                  occupation={selectedClient?.occupation}
                  customerId={selectedClient?.customerId ?? "N/A"}
                  referredBy={selectedClient?.referredBy}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <AccountHistory
                  history={paymentArray}
                  title="Service, Payment & Balance History"
                  addHandler={() => {
                    setMode("create");
                    setNewPaymentModal(true);
                  }}
                  editHandler={(item) => {
                    setMode("edit");
                    setSelectedItem(item);
                    setNewPaymentModal(true);
                  }}
                  viewHandler={viewHandler}
                />
              </Grid>
            </Grid>
          </SuiBox>

          <Card>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <SuiBox
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  pl={2.5}
                  mb={-1}
                  mt={3}
                >
                  <SuiTypography variant="h6">Assessments</SuiTypography>
                </SuiBox>
                <Divider />
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    alignItems: "left",
                    background: "transparent",
                    minHeight: "100%",
                    borderRadius: 0,
                    "&.MuiTabs-flexContainer": {
                      alignItems: "flex-start !important",
                    },
                    "&.MuiTabs-flexContainerVertical": {
                      justifyContent: "flex-start",
                      alignItems: "flex-start !important",
                    },
                  }}
                >
                  {/* <Tab
                    label="Dietary & Lifestyle"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#17C1E8",
                        color: "white !important",
                      },
                      "&.MuiTab-root": {
                        justifyContent: "flex-start",
                        paddingLeft: "10px !important",
                      },
                    }}
                    {...a11yProps(0)}
                  /> */}
                  <Tab
                    label="Dietary, Lifestyle & Biochemical"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#17C1E8",
                        color: "white !important",
                      },
                      "&.MuiTab-root": {
                        justifyContent: "flex-start",
                        paddingLeft: "10px !important",
                        textAlign: "left !important",
                      },
                    }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Physical Activity"
                    {...a11yProps(1)}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#17C1E8",
                        color: "white !important",
                      },
                      "&.MuiTab-root": {
                        justifyContent: "flex-start",
                        paddingLeft: "10px !important",
                      },
                    }}
                  />
                  <Tab
                    label="Measurements & Vitals"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#17C1E8",
                        color: "white !important",
                      },
                      "&.MuiTab-root": {
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        paddingLeft: "10px !important",
                        textAlign: "left !important",
                      },
                    }}
                    {...a11yProps(2)}
                  />

                  <Tab
                    label="Dietary Recall"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#17C1E8",
                        color: "white !important",
                      },
                      "&.MuiTab-root": {
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        paddingLeft: "10px !important",
                      },
                    }}
                    {...a11yProps(3)}
                  />

                  <Tab
                    label="Client's Daily Intake"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#17C1E8",
                        color: "white !important",
                      },
                      "&.MuiTab-root": {
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        paddingLeft: "10px !important",
                      },
                    }}
                    {...a11yProps(4)}
                  />

                  <Tab
                    label="Image Now vs Earlier"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#17C1E8",
                        color: "white !important",
                      },
                      "&.MuiTab-root": {
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        paddingLeft: "10px !important",
                      },
                    }}
                    {...a11yProps(5)}
                  />
                </Tabs>
              </Grid>

              <Grid item xs={12} md={9} lg={9}>
                {/* <TabPanel value={tabValue} index={0}>
                  <DietLifestyleAssessmentTableCard
                    id={id}
                    assessments={dietaryArray}
                    title="Dietary & Lifestyle Assessment"
                    setSelectedClient={setSelectedClient}
                  />
                </TabPanel> */}
                <TabPanel value={tabValue} index={0}>
                  <BiochemicalHealthRecords
                    id={id}
                    isSuccess={isSuccess}
                    isError={isError}
                    isFetching={isFetching}
                    loader={loader}
                    setLoader={setLoader}
                    message={message}
                    biochemicals={biochemicalArray}
                    title="Dietary, Lifestyle & Biochemical Assessment"
                    setSelectedClient={setSelectedClient}
                    deleteHandler={(item, index, persona) => {
                      setSelectedItem({
                        id,
                        persona,
                        ID: item?.id,
                        selectedItemIndex: index,
                      });
                      setDeleteModal(true);
                    }}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                  <PhysicalActivityTracker
                    id={id}
                    physicalActs={physicalArray}
                    title="Physical Activity, Fitness & Lifecycle"
                    setSelectedClient={setSelectedClient}
                    deleteHandler={(item, index, persona) => {
                      setSelectedItem({
                        id,
                        persona,
                        ID: item?.id,
                        selectedItemIndex: index,
                      });
                      setDeleteModal(true);
                    }}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                  <SuiBox mb={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MeasurementDataVisualizer
                          id={id}
                          measurements={measurementArray}
                          title="Measurements"
                          setSelectedClient={setSelectedClient}
                          deleteHandler={(item, index, persona) => {
                            setSelectedItem({
                              id,
                              persona,
                              ID: item?.id,
                              selectedItemIndex: index,
                            });
                            setDeleteModal(true);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <VitalDataVisualizer
                          id={id}
                          vitals={vitalArray}
                          title="Vitals"
                          setSelectedClient={setSelectedClient}
                          deleteHandler={(item, index, persona) => {
                            setSelectedItem({
                              id,
                              persona,
                              ID: item?.id,
                              selectedItemIndex: index,
                            });
                            setDeleteModal(true);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </SuiBox>
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                  <DietRecallSummary
                    id={id}
                    dietRecall={dietRecallArray}
                    setSelectedClient={setSelectedClient}
                    title="Dietary Recall"
                    deleteHandler={(item, index, persona) => {
                      setSelectedItem({
                        id,
                        persona,
                        ID: item?.id,
                        selectedItemIndex: index,
                      });
                      setDeleteModal(true);
                    }}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={4}>
                  <DailyIntakeForm
                    id={id}
                    dailyIntakeArray={dailyIntakeArray}
                    title="Client's Daily Intake"
                    setSelectedClient={setSelectedClient}
                    deleteHandler={(item, index, persona) => {
                      setSelectedItem({
                        id,
                        persona,
                        ID: item?.id,
                        selectedItemIndex: index,
                      });
                      setDeleteModal(true);
                    }}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={5}>
                  <ImageNowVsEarlier
                    images={imageArray1}
                    uid={id}
                    setSelectedClient={setSelectedClient}
                    title="Image Now vs Earlier"
                    deleteHandler={(item, index, persona) => {
                      setSelectedItem({
                        id,
                        persona,
                        ID: item?.image,
                        selectedItemIndex: index,
                      });
                      setDeleteModal(true);
                    }}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </Card>

          <SuiBox my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomDietPdf
                  customDietPdf={customDietPdf}
                  title={`Upload Your Custom Diet Chart for ${selectedClient?.name}`}
                  clientId={id}
                  setSelectedClient={setSelectedClient}
                />
              </Grid>
            </Grid>
          </SuiBox>

          <SuiBox my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ProductPurchases
                  products={productArray}
                  title="Product Purchase History"
                  clientId={id}
                  viewHandler={viewHandler}
                  setSelectedClient={setSelectedClient}
                  editHandler={(item) => {
                    editProductHandler(item);
                  }}
                  deleteHandler={(id, name, serviceId) => {
                    setSelectedItem({
                      id,
                      name,
                      persona: "product",
                      ID: serviceId,
                    });
                    setDeleteModal(true);
                  }}
                  mailCheckHandler={mailCheckHandler}
                />
              </Grid>
            </Grid>
            {invoiceArr.length > 0 ? (
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "1rem",
                  width: "auto",
                  zIndex: 10,
                }}
              >
                <CustomButton
                  color="dark"
                  variant="gradient"
                  onClick={() => setIsInvoiceModal(true)}
                  fullWidth
                  width={180}
                  title={"Mail & Download Invoice"}
                  loader={isInvoiceLoading}
                />
                {/* <SuiButton
                  component={Button}
                  color="dark"
                  variant="gradient"
                  onClick={mailDownloadButtonHandler}
                >
                  Mail & Download Invoice
                </SuiButton>
                {isInvoiceLoading && (
                  <TailSpin color="#17c1e8" height={20} width={20} />
                )} */}
              </div>
            ) : null}
          </SuiBox>
        </SuiBox>
      )}

      {deleteModal && (
        <DeleteModal
          isFetching={isFetching}
          onDeleteHandler={deleteHandler}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}

      <EditProfileModal
        open={editProfileModal}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setSelectedClient={setSelectedClient}
        families={families}
        handleCloseModal={() => {
          setEditProfileModal(false);
          setSelectedItem(null);
        }}
      />

      {newPaymentModal && (
        <PaymentModal
          open={newPaymentModal}
          id={id}
          mode={mode}
          services={services}
          selectedItem={selectedItem}
          setSelectedClient={setSelectedClient}
          setSelectedItem={setSelectedItem}
          handleCloseModal={() => {
            setNewPaymentModal(false);
            setSelectedItem(null);
          }}
          setMode={setMode}
          viewHandler={viewHandler}
        />
      )}

      {newSellModal && (
        <SoldProductModal
          open={newSellModal}
          isSuccess={isSuccess}
          isError={isError}
          isFetching={isFetching}
          loader={loader}
          setLoader={setLoader}
          message={message}
          clientId={id}
          mode={mode}
          handleCloseModal={() => {
            setTimeout(() => {
              setSelectedItem(null);
            }, 500);
            setNewSellModal(false);
          }}
          selectedItem={selectedItem}
          setMode={setMode}
        />
      )}
    </DashboardLayout>
  );
}

export default ClientDetail;
