import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import * as XLSX from "xlsx";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Table from "examples/Tables/Table";
import Searchbar from "containers/Searchbar";
import moment from "moment";
import NoData from "containers/DataNotAvailBox";
import { serviceRow } from "./rows.js";
import ServiceSearch from "./search.js";
import ServiceStat from "./stat.js";
import { useNavigate } from "react-router-dom";
import { timestampConversion } from "utils/common";
import { TailSpin } from "react-loader-spinner";
import { sortData } from "utils/sort.js";
import DeleteModal from "containers/DeleteModal/index.js";
import { toast } from "react-toastify";
import { blobToBase64 } from "utils/common.js";
import { sendInvoicePdf } from "firebaseConfig.js";
import MyInvoiceDoc from "containers/pdf/billingPdf";
import { pdf } from "@react-pdf/renderer";

function ConsultationTable({
  consultationBilling,
  services,
  isSuccess,
  isFetching,
  loader,
  user,
  setInvoiceDataHandler,
  viewHandler,
  downloadAndStorePdf,
}) {
  let navigate = useNavigate(); //for navigation

  // options for dropdown of service filter
  const [serviceOptions, setServiceOptions] = useState([]); //filtering wrt services
  const [loading, setLoading] = useState(null);
  const [formdata, setFormdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    let serviceArray = services.map((item, index) => {
      return {
        value: item?.serviceName,
        label: item?.serviceName,
        key: index,
      };
    });
    setServiceOptions(serviceArray);
  }, [services]);

  const [rows, setRows] = useState([]); // rows for billing table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [serviceArray, setserviceArray] = useState([]);

  // for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
    sortKey: "",
  });

  const columns = [
    { name: "S.no", align: "left", width: "30px" },

    {
      name: "Client Name",
      align: "left",
      minWidth: "150px",
      sort: true,
      sortKey: "name",
    },
    // { name: "Customer ID", align: "left", minWidth: '110px' },

    {
      name: "Date/Time",
      align: "left",
      minWidth: "90px",
      sort: true,
      sortKey: "createdAt",
    },
    {
      name: "Phone",
      align: "left",
      minWidth: "120px",
      sort: true,
      sortKey: "phoneNumber",
    },
    // { name: "Email", align: "left", minWidth: '120px', sort: true, sortKey: "email" },
    { name: "Package", align: "left", minWidth: "170px" },
    { name: "Balance", align: "left", minWidth: "100px" },
    // { name: "Total Amount", align: "left", minWidth: '120px' },
    // { name: "Amount Paid", align: "left", minWidth: '120px' },
    // { name: "Discount", align: "left", minWidth: '120px' },
    // { name: "Balance", align: "left", minWidth: '120px' },
    { name: "Action", align: "left", minWidth: "120px" },
  ];

  useEffect(() => {
    const sortedRows = sortData(serviceArray, sortConfig);
    setRows(
      serviceRow(
        sortedRows,
        navigate,
        setInvoiceDataHandler,
        viewHandler,
        loading,
        formdata,
        setFormdata
      )
    );
  }, [
    serviceArray,
    navigate,
    user,
    setInvoiceDataHandler,
    sortConfig,
    viewHandler,
    downloadAndStorePdf,
    loading,
  ]);

  const handleSort = (name, sortKey) => {
    let direction = "asc";
    if (sortConfig.key === name && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: name, direction, sortKey });
  };

  const [filterQuery, setFilterQuery] = useState({
    service: null,
    fromDate: null,
    toDate: null,
  }); // for filtering

  const filterHandler = () => {
    setsearchVal("");
    let timestamp;
    let filterData = consultationBilling.filter((item) => {
      if (filterQuery.service) {
        if (filterQuery.service === "all") {
          setFilterQuery({ ...filterQuery, service: null });
        } else if (filterQuery.service !== item["serviceName"]) {
          return false;
        }
      }

      if (filterQuery.fromDate) {
        timestamp = item.createdAt;
        timestamp =
          timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000;
        timestamp = moment.utc(timestamp);

        if (
          new Date(filterQuery.fromDate) >
          new Date(moment(timestamp).format("YYYY-MM-DD"))
        ) {
          return false;
        }
      }

      if (filterQuery.toDate) {
        timestamp = item.createdAt;
        timestamp =
          timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000;
        timestamp = moment.utc(timestamp);

        if (
          moment
            .utc(moment(filterQuery.toDate).add(24, "hours"))
            .isBefore(timestamp)
        ) {
          return false;
        }
      }
      return item;
    });
    setserviceArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      service: null,
      fromDate: null,
      toDate: null,
    });
    setserviceArray(consultationBilling);
  }, [consultationBilling]);

  //use for search with unique code field
  useEffect(() => {
    if (!searchVal) {
      resetHandler();
      setserviceArray(consultationBilling);
    } else {
      resetHandler();
      let filteredService = consultationBilling.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setserviceArray((e) => filteredService);
    }
  }, [searchVal, isSuccess, isFetching, consultationBilling, resetHandler]);

  const excelDownload = () => {
    let updatedData = serviceArray.map((item, index) => {
      let time1 = item?.createdAt?.seconds
        ? moment(timestampConversion(item?.createdAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : moment(item?.createdAt).local().format("MMMM Do YYYY, h:mm a");
      return [
        index + 1,
        item.name,
        item.customerId ?? "N/A",
        time1,
        item.phoneNumber,
        item.email,
        item.serviceName,
        item.totalAmount,
        item.amountPaid,
        item.discount,
        item.totalAmount - (item.amountPaid + item.discount),
      ];
    });
    let excelData = [
      [
        "id",
        "CLIENT NAME",
        "CUSTOMER ID",
        "DATE/TIME",
        "PHONE",
        "EMAIL",
        "PACKAGE",
        "TOTAL AMOUNT",
        "AMOUNT PAID",
        "DISCOUNT",
        "BALANCE",
      ],
      ...updatedData,
    ];
    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Client_List_Consultation_Services.xlsx");
  };

  const onModalConfirmHandler = async () => {
    let numberRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let val = formdata.email;
    let match_ = val.match(numberRegex);
    if (!match_ && val) {
      return toast.error(`Please enter valid email!`);
    }
    if (
      formdata?.email === null ||
      formdata?.email === undefined ||
      formdata?.email === ""
    ) {
      toast.info(`Please enter email!`);
      return;
    }

    setErrorMessage({
      ...errorMessage,
      email: false,
    });

    setFormdata({ ...formdata, modal: false });

    const blob = await pdf(
      <MyInvoiceDoc
        user={user}
        bill={{ ...formdata }}
        note={user.termsAndConditions}
        type={formdata.type}
      />
    ).toBlob();

    const invoicePdfBase64 = await blobToBase64(blob);
    setLoading(formdata.index);

    await sendInvoicePdf({
      clientName: formdata.name,
      clientEmail: formdata.email,
      invoicePdfBlob: invoicePdfBase64,
      logo: user.logo,
      name: user.name,
    });
    await downloadAndStorePdf({ bill: { ...formdata }, user });
    toast.info("Invoice sent & created successfully");
    setLoading(null);
  };

  return (
    <SuiBox mb={3}>
      {formdata.modal && (
        <DeleteModal
          title="Enter the email address to send the invoice:"
          isInput
          inputName="email"
          formdata={formdata}
          setFormdata={setFormdata}
          onDeleteHandler={onModalConfirmHandler}
        />
      )}
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
          flexDirection={{ xs: "column-reverse", lg: "row" }}
          gap={1}
        >
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={"dark"}
            sx={{ mb: 0.75 }}
          >
            Below are the clients, who have taken consultation, you can generate
            their invoice.
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Client Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />
          </SuiBox>
        </SuiBox>

        <ServiceSearch
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          serviceOptions={serviceOptions}
          excelDownload={excelDownload}
        />

        <ServiceStat service={serviceArray} />

        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table
              columns={columns}
              rows={rows}
              handleSort={handleSort}
              sortConfig={sortConfig}
            />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default ConsultationTable;
