import React, { useState, useEffect, useMemo, useCallback } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import RecipeChart from "./recipeChart";
import Createable from "containers/Select";
import NoteInput from "./noteInput";
import { getWeeksArray } from "utils/common";
import CustomeDatepicker from "containers/Datepicker";

import { doc, getDoc } from "firebase/firestore";
import moment from "moment";
import { firestore } from "firebaseConfig";
import { TailSpin } from "react-loader-spinner";
import CustomButton from "containers/CustomButton";

import { getUserRecipes, AddDiet, EditDietChart } from "services/dietchart";
import { getClientDietChartForDate } from "services/dietchart";
import ModalHeader from "containers/modal-header";
import { useSelector, useDispatch } from "react-redux";
import {
  userSelector,
  getSavedDietChartTemplates,
} from "store/features/authSlice";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeselectIcon from "@mui/icons-material/Deselect";
function Modal({
  open,
  clients,
  handleCloseModal,
  id,
  selectedItem,
  setSelectedItem,
  setDietChart,
  client,
  setSelectedClientv1,
  editValue,
  mode,
  editHandler,
  viewHandler,
}) {
  const { dietChartTemplates } = useSelector(userSelector);
  const [selectedTemplate, setSelectedTemplate] = useState({
    label: "",
    value: {},
  });
  const [dietChartTemplateNames, setDietChartTemplateNames] = useState([]);

  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  useEffect(() => {
    dispatch(getSavedDietChartTemplates({ uid: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (dietChartTemplates) {
      const templateNames = dietChartTemplates.map((template) => {
        return {
          label: template.templateName,
          value: {
            dietPlan: template.dietPlan,
            chartType: template.chartType,
            weekPlan: template.weekPlan,
            note: template.note,
          },
        };
      });
      setDietChartTemplateNames(templateNames);
    }
  }, [dietChartTemplates]);

  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      clientName: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      clientName: null,
      date: null,
      notes: null,
      chartType: null,
      weekPlan: null,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);

  const [scrollToTop, setScrollToTop] = useState(0);
  const [isClientTermsAccepted, setIsClientTermsAccepted] = useState(0);

  const [errorMessage, setErrorMessage] = useState(errorDefault);
  const [loader, setLoader] = useState(false);

  //for recipes
  const [recipeLoader, setRecipeLoader] = useState(false);

  useEffect(() => {
    const gridEl = document.getElementById("grid");
    if (gridEl) {
      gridEl.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollToTop]);

  const getRecipesHandler = useCallback(async (id) => {
    try {
      setRecipeLoader(true);
      const data = await getUserRecipes(id);
      setRecipes(data);
      setRecipeLoader(false);
    } catch (err) {
      setRecipeLoader(false);
      toast.error("Something went wrong!!!");
    }
  }, []);
  const [recipes, setRecipes] = useState([]);
  useEffect(() => {
    if (open) {
      getRecipesHandler(id);
    } else {
      setRecipes([]);
    }
  }, [open, id, getRecipesHandler]);

  //default modal fields value
  const defaultHandlerv1 = useMemo(() => {
    return {
      id: null,
      name: null,
      description: null,
      preparation: null,
      calories: null,
      foodType: null,
      ingredients: null,
      nutritions: null,
      howToTake: null,
      weight: null,
      foodCategory: null,
      qty: null,
      unit: null,
      time: null,
    };
  }, []);

  let dietChartObj = useMemo(() => {
    return {
      earlyMorning: [],
      breakfast: [],
      midMorning: [],
      brunch: [],
      midMeal: [],
      lunch: [],
      postLunch: [],
      evening: [],
      beforeDinner: [],
      dinner: [],
      postDinner: [],
      lateNight: [],
    };
  }, []);

  const [dietPlan, setDietPlan] = useState({ ...dietChartObj });

  const defaultErrorv1 = {
    name: false,
    description: true,
    preparation: false,
    calories: false,
    foodType: false,
    ingredients: false,
    nutritions: false,
    howToTake: false,
    weight: false,
    foodCategory: false,
  };

  const [chartError, setChartError] = useState({
    earlyMorning: [defaultErrorv1],
    breakfast: [defaultErrorv1],
    midMorning: [defaultErrorv1],
    brunch: [defaultErrorv1],
    midMeal: [defaultErrorv1],
    lunch: [defaultErrorv1],
    postLunch: [defaultErrorv1],
    evening: [defaultErrorv1],
    beforeDinner: [defaultErrorv1],
    dinner: [defaultErrorv1],
    postDinner: [defaultErrorv1],
    lateNight: [defaultErrorv1],
  });

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.clientName) {
      toast.error("Please fill Client Name field");
      setErrorMessage({
        ...errorMessage,
        clientName: true,
      });
      return;
    }

    if (!dietPlan.date) {
      toast.error("Please fill Diet for Date field");
      setErrorMessage({
        ...errorMessage,
        date: true,
      });
      return;
    }

    if (!dietPlan.weekPlan) {
      toast.error("Please fill Week Plan field");
      setErrorMessage({
        ...errorMessage,
        weekPlan: true,
      });
      return;
    }

    if (!dietPlan.chartType) {
      toast.error("Please fill Chart Type field");
      setErrorMessage({
        ...errorMessage,
        chartType: true,
      });
      return;
    }

    if (!dietPlan.notes) {
      toast.error("Please fill Notes for Client field");
      setErrorMessage({
        ...errorMessage,
        notes: true,
      });
      return;
    }

    let keys = Object.keys(dietPlan);

    for (let i = 0; i < keys.length; i++) {
      if (
        dietPlan[keys[i]] &&
        keys[i] !== "date" &&
        keys[i] !== "notes" &&
        keys[i] !== "id" &&
        keys[i] !== "selected" &&
        keys[i] !== "date" &&
        keys[i] !== "clientName" &&
        keys[i] !== "dietChartId" &&
        keys[i] !== "clientId" &&
        keys[i] !== "createdAt" &&
        keys[i] !== "weekPlan" &&
        keys[i] !== "chartType"
      ) {
        let recipe = dietPlan[keys[i]];
        let recipeId = keys[i];
        const recipeIds = [
          "earlyMorning",
          "breakfast",
          "midMorning",
          "brunch",
          "midMeal",
          "lunch",
          "postLunch",
          "evening",
          "beforeDinner",
          "dinner",
          "postDinner",
          "lateNight",
        ];
        const recipeIds1 = [
          "Early Morning",
          "Breakfast",
          "Mid Morning",
          "Brunch",
          "Mid Meal",
          "Lunch",
          "Post Lunch",
          "Evening",
          "Before Dinner",
          "Dinner",
          "Post Dinner",
          "Late Night",
        ];

        for (let j = 0; j < recipe.length; j++) {
          if (
            recipe[j].name ||
            recipe[j].description ||
            recipe[j].preparation ||
            recipe[j].howToTake ||
            recipe[j].nutritions ||
            recipe[j].ingredients ||
            recipe[j].foodType ||
            recipe[j].calories ||
            recipe[j].weight ||
            recipe[j].foodCategory ||
            recipe[j].unit ||
            recipe[j].qty
          ) {
            if (!recipe[j].name && recipeId === recipeIds[i]) {
              toast.error(
                `Please enter a Recipe Name field for ${recipeIds1[i]}`
              );
              setChartError({
                ...chartError,
                [recipeId]: {
                  name: true,
                },
              });
              return;
            }

            if (!recipe[j].description && recipeId === recipeIds[i]) {
              toast.error(
                `Please enter a Recipe Descripiton field for ${recipeIds1[i]}`
              );
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  description: true,
                },
              });
              return;
            }

            if (!recipe[j].preparation && recipeId === recipeIds[i]) {
              toast.error(
                `Please fill How to Prepare field for ${recipeIds1[i]}`
              );
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  preparation: true,
                },
              });
              return;
            }

            if (!recipe[j].howToTake && recipeId === recipeIds[i]) {
              toast.error(`Please fill How to Take field for ${recipeIds1[i]}`);
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  howToTake: true,
                },
              });
              return;
            }

            // if((!recipe[j].nutritions || recipe[j].nutritions.length===0)&& recipeId===recipeIds[i]){
            //     toast.error(`Please fill Nutrition Value field for ${recipeIds1[i]}`)
            //     setChartError({
            //         ...errorMessage,
            //         [recipeId]: {
            //             "nutritions": true
            //         }
            //     })
            //     return;
            // }

            if (
              (!recipe[j].ingredients || recipe[j].ingredients.length === 0) &&
              recipeId === recipeIds[i]
            ) {
              toast.error(`Please fill Ingredients field for ${recipeIds1[i]}`);
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  ingredients: true,
                },
              });
              return;
            }

            if (
              (!recipe[j].foodType || recipe[j].foodType.length === 0) &&
              recipeId === recipeIds[i]
            ) {
              toast.error(`Please fill Food Type field for ${recipeIds1[i]}`);
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  foodType: true,
                },
              });
              return;
            }

            // if(!recipe[j].calories && recipeId===recipeIds[i]){
            //     toast.error(`Please enter Calories field for ${recipeIds1[i]}`)
            //     setChartError({
            //         ...errorMessage,
            //         [recipeId]: {
            //             "calories": true
            //         }
            //     })
            //     return;
            // }

            // if(!recipe[j].weight && recipeId===recipeIds[i]){
            //     toast.error(`Please enter Weight field for ${recipeIds1[i]}`)
            //     setChartError({
            //         ...errorMessage,
            //         [recipeId]: {
            //             "weight": true
            //         }
            //     })
            //     return;
            // }

            if (!recipe[j].qty && recipeId === recipeIds[i]) {
              toast.error(`Please enter Qty. field for ${recipeIds1[i]}`);
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  qty: true,
                },
              });
              return;
            }

            if (!recipe[j].unit && recipeId === recipeIds[i]) {
              toast.error(`Please fill Unit field for ${recipeIds1[i]}`);
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  unit: true,
                },
              });
              return;
            }

            if (!recipe[j].foodCategory && recipeId === recipeIds[i]) {
              toast.error(
                `Please fill Food Category field for ${recipeIds1[i]}`
              );
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  foodCategory: true,
                },
              });
              return;
            }

            if (!recipe[j].time && recipeId === recipeIds[i]) {
              toast.error(`Please fill Time field for ${recipeIds1[i]}`);
              setChartError({
                ...errorMessage,
                [recipeId]: {
                  time: true,
                },
              });
              return;
            }
          } else if (recipeId === recipeIds[i]) {
            toast.error(
              `Please fill all added recipe fields for ${recipeIds1[i]}`
            );
            setChartError({
              ...errorMessage,
              [recipeId]: {
                name: true,
                description: true,
                preparation: true,
                calories: true,
                foodType: true,
                ingredients: true,
                nutritions: true,
                howToTake: true,
                weight: true,
                foodCategory: true,
              },
            });
            return;
          }
        }
      }
    }

    try {
      setLoader(true);
      if (selectedItem && !selectedDate && !selectedTemplate.label) {
        const diet = await EditDietChart({ formdata, dietPlan, uid: id });
        setDietChart((e) => {
          e = e.map((item) => {
            if (item.dietChartId === diet.dietChartId) {
              return { ...diet };
            }
            return item;
          });
          return [...e];
        });
        toast.success("Diet updated successfully!!");
      } else {
        await AddDiet({ formdata, dietPlan, uid: id });
        setSelectedClientv1(formdata.clientName);
        toast.success("Diet added successfully!!");
      }
      setLoader(false);
      handleCloseModal();
      setTimeout(() => {
        setNote("");
        setSelectedDate(null);
        if (selectedItem) {
          setSelectedItem(null);
        }
        setFormdata({
          ...defaultHandler,
        });

        setDietPlan({ ...dietChartObj });
        setErrorMessage({
          ...errorDefault,
        });
      }, 500);
    } catch (e) {
      setLoader(false);
      console.log(e);
      toast.error("Somethig went wrong, retry!!");
    }
  };

  const timeline = useMemo(
    () => [
      { title: "Early Morning", id: "earlyMorning" },
      { title: "Breakfast", id: "breakfast" },
      { title: "Mid Morning", id: "midMorning" },
      { title: "Brunch", id: "brunch" },
      { title: "Mid Meal", id: "midMeal" },
      { title: "Lunch", id: "lunch" },
      { title: "Post Lunch", id: "postLunch" },
      { title: "Evening", id: "evening" },
      { title: "Before Dinner", id: "beforeDinner" },
      { title: "Dinner", id: "dinner" },
      { title: "Post Dinner", id: "postDinner" },
      { title: "Late Night", id: "lateNight" },
    ],
    []
  );

  const [totalCalories, setTotalCalories] = useState(0);
  useEffect(() => {
    let keys = Object.keys(dietPlan);
    let calories = 0;

    for (let i = 0; i < keys.length; i++) {
      if (
        keys[i] !== "date" &&
        keys[i] !== "notes" &&
        keys[i] !== "id" &&
        keys[i] !== "selected" &&
        keys[i] !== "date" &&
        keys[i] !== "name" &&
        keys[i] !== "clientName" &&
        keys[i] !== "dietChartId" &&
        keys[i] !== "createdAt" &&
        keys[i] !== "weekPlan" &&
        keys[i] !== "chartType" &&
        keys[i] !== "clientId"
      ) {
        calories += dietPlan?.[keys[i]]?.reduce((acc, plan) => {
          acc +=
            plan.calories && !isNaN(plan.calories)
              ? parseFloat(plan.calories)
              : 0;
          return acc;
        }, 0);
      }
    }
    setTotalCalories(calories);
  }, [dietPlan]);

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (selectedItem !== null && open) {
      if (!selectedDate) {
        setFormdata((e) => {
          return {
            ...e,
            date: selectedItem?.date,
            notes: selectedItem?.notes,
            clientName: client
              ? {
                  label: client?.id
                    ? client?.name + ` (+91${client?.phoneNumber})`
                    : "",
                  value: client?.id,
                  name: client?.name,
                }
              : e.clientName,
          };
        });
      }

      setDietPlan((e) => {
        let newDiet = {
          ...e,
          ...selectedItem,
          dietChartId: selectedItem?.dietChartId,
          weekPlan: {
            label: selectedItem?.weekPlan,
            value: selectedItem?.weekPlan,
          },
          chartType: {
            label: selectedItem?.chartType,
            value: selectedItem?.chartType,
          },
          date: dietPlan?.date ?? selectedItem?.date,
          notes: selectedItem?.notes,
          createdAt: selectedItem?.createdAt,
        };
        return newDiet;
      });
    }
  }, [selectedItem, client, timeline, open, dietPlan.date, selectedDate]);

  const chartTypes = [
    { value: "Weekly", label: "Weekly", key: 1 },
    { value: "Plateau", label: "Plateau", key: 2 },
    { value: "Detox", label: "Detox", key: 3 },
  ];

  //recipe list
  const [recipeList, setRecipeList] = useState([]);
  useEffect(() => {
    const recipeArray = recipes.map((item, index) => {
      return {
        label: item.name,
        value: item.name,
        key: index,
        id: item?.id,
        name: { label: item.name, value: item.name },
        description: item?.description,
        preparation: item?.preparation,
        calories: item?.calories,
        foodType: item?.foodType,
        ingredients: item?.ingredients,
        nutritions: item?.nutritions,
        howToTake: item?.howToTake,
        weight: item?.weight,
        foodCategory: { value: item?.foodCategory, label: item?.foodCategory },
        qty: item?.qty,
        unit: { value: item.unit, label: item.unit },
      };
    });

    setRecipeList(recipeArray);
  }, [recipes]);

  const [dietLoader, setDietLoader] = useState(false);
  async function getClientDietChart(date) {
    try {
      let dates = includeDates.filter((item) => {
        return moment(item.date).isSame(date, "day");
      });

      setDietLoader(true);
      const ref = doc(firestore, "dietplan", dates?.[0]?.id);
      const snapshot = await getDoc(ref);
      let data;
      let dietChartData = {};
      if (snapshot.exists()) {
        data = snapshot.data();

        let keys = Object.keys(data);

        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] !== "date" &&
            keys[i] !== "notes" &&
            keys[i] !== "clientId" &&
            keys[i] !== "clientName" &&
            keys[i] !== "createdAt" &&
            keys[i] !== "dietChartId" &&
            keys[i] !== "weekPlan" &&
            keys[i] !== "chartType"
          ) {
            if (Array.isArray(data[keys[i]])) {
              dietChartData[keys[i]] = [...data[keys[i]]];
            }
          }
        }
        dietChartData = {
          ...dietChartData,
          weekPlan: data?.weekPlan,
          chartType: data?.chartType,
          notes: data?.notes,
        };

        setSelectedItem(dietChartData);
        setDietLoader(false);
      } else {
        setDietLoader(false);
        toast.error("Client not found!!!");
      }
    } catch (err) {
      setDietLoader(false);
      toast.error("Server error!!!");
    }
  }

  //for cloning diet chart
  const [includeDates, setIncludeDates] = useState([]);
  const getIncludesDates = useCallback(async () => {
    try {
      setDietLoader(true);
      const id = formdata.clientName.value;
      const { data, note } = await getClientDietChartForDate(id);
      setIncludeDates(data);
      setNote(note);
      setDietPlan((e) => {
        e["notes"] = note;
        return e;
      });
      setDietLoader(false);
    } catch (err) {
      console.log(err, "error");
      setDietLoader(false);
    }
  }, [formdata?.clientName?.value]);

  useEffect(() => {
    if (formdata?.clientName?.value) {
      getIncludesDates();
    }
  }, [getIncludesDates, formdata?.clientName?.value]);

  const onChangeDate = (date) => {
    setNote("");
    setSelectedTemplate({ label: "", value: {} });
    setDietPlan({ ...dietChartObj });
    setSelectedItem(null);
    if (date === null) return;
    if (!formdata.clientName) {
      toast.error("Please fill Client Name field first");
      return;
    }
    if (date) {
      setSelectedDate(date);
      getClientDietChart(date);
    } else {
      setSelectedItem({});
      setSelectedDate("");
    }
  };

  useEffect(() => {
    if (!editValue) {
      if (formdata?.clientName?.value) {
        setDietPlan({ ...dietChartObj });
        setSelectedItem(null);
        setSelectedTemplate({ label: "", value: {} });
        setSelectedDate(null);
        setIncludeDates(formdata.clientName?.dietDates ?? []);
      } else {
        setIncludeDates([]);
      }
    }
  }, [
    formdata?.clientName,
    editValue,
    formdata?.clientName?.dietDates,
    dietChartObj,
    setSelectedItem,
  ]);

  //for geting notes from previou day
  useEffect(() => {
    if (!editValue) {
      if (formdata?.clientName && formdata?.clientName?.note) {
        setNote(formdata.clientName.note);
        setDietPlan((e) => {
          e["notes"] = formdata.clientName.note;
          return e;
        });
      } else {
        setNote("");
        setDietPlan((e) => {
          e["notes"] = "";
          return e;
        });
      }
    }
  }, [formdata?.clientName, editValue]);

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="100%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
        id="grid"
      >
        <ModalHeader
          mode={mode}
          title="Diet Chart"
          subtitle={`You can view diet chart details below`}
        />

        <SuiBox ml={1} display="flex" alignItems="center">
          {isClientTermsAccepted ? (
            <CheckBoxIcon
              fontSize="medium"
              style={{ color: "green", marginRight: "8px" }}
            />
          ) : (
            <DeselectIcon
              fontSize="medium"
              style={{ color: "red", marginRight: "8px" }}
            />
          )}

          <SuiTypography display="inline" variant="h6">
            {isClientTermsAccepted ? "T & C Accepted" : "T & C Not Accepted"}
          </SuiTypography>
        </SuiBox>

        <Grid item>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                editHandler(selectedItem);
              }}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(selectedItem)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setSelectedDate(null);
              setSelectedTemplate({ label: "", value: {} });
              setNote("");
              setDietPlan({ ...dietChartObj });
              setSelectedItem(null);
              setFormdata(defaultHandler);
            }}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <Grid container spacing={3} mb={4}>
          <Grid item xs={12} md={2}>
            <SuiBox lineHeight={1} mt={3}>
              <SuiTypography mb={1} variant="h6">
                Client Name
              </SuiTypography>
              <Createable
                options={
                  open && editValue
                    ? [
                        {
                          label: client?.name + ` (+91${client?.phoneNumber})`,
                          value: client?.id,
                          name: client?.name,
                        },
                      ]
                    : clients ?? []
                }
                disabled={mode === "view"}
                error={errorMessage?.clientName}
                value={formdata?.clientName}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Choose client"
                onChange={(e) => {
                  setSelectedTemplate({ label: "", value: {} });
                  setNote("");
                  setFormdata({
                    ...formdata,
                    clientName: e,
                  });
                  setIsClientTermsAccepted(e.isClientTermsAccepted);
                  setErrorMessage({
                    ...errorMessage,
                    clientName: false,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={2}>
            <SuiBox lineHeight={1} mt={3}>
              <SuiTypography mb={1} variant="h6">
                Diet for Date
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="date"
                name="date"
                readOnly={mode === "view"}
                error={errorMessage?.date}
                value={dietPlan?.date ?? selectedItem?.date ?? ""}
                onChange={(e) => {
                  setDietPlan({
                    ...dietPlan,
                    [e.target.name]: e.target.value,
                  });
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={2}>
            <SuiBox lineHeight={1} mt={3}>
              <SuiTypography mb={1} variant="h6">
                Week Plan
              </SuiTypography>
              <Createable
                options={getWeeksArray() ?? []}
                disabled={mode === "view"}
                error={errorMessage?.weekPlan}
                value={dietPlan?.weekPlan ?? ""}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Week plan"
                onChange={(e) => {
                  setDietPlan({
                    ...dietPlan,
                    weekPlan: e,
                  });

                  setErrorMessage({
                    ...errorMessage,
                    weekPlan: false,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={2}>
            <SuiBox lineHeight={1} mt={3}>
              <SuiTypography mb={1} variant="h6">
                Chart Type
              </SuiTypography>
              <Createable
                options={chartTypes ?? []}
                disabled={mode === "view"}
                error={errorMessage?.chartType}
                value={dietPlan?.chartType ?? ""}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Chart type"
                onChange={(e) => {
                  setDietPlan({
                    ...dietPlan,
                    chartType: e,
                  });

                  setErrorMessage({
                    ...errorMessage,
                    chartType: false,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={2}>
            <SuiBox lineHeight={1} mt={3}>
              <SuiTypography mb={1} variant="h6">
                Select Template
              </SuiTypography>
              <Createable
                options={dietChartTemplateNames}
                value={selectedTemplate}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Select Template"
                onChange={(e) => {
                  setSelectedDate(null);
                  setSelectedTemplate(e);
                  setSelectedItem((pre) => {
                    return {
                      ...pre,
                      ...e.value.dietPlan,
                      weekPlan: e.value.weekPlan.value,
                      chartType: e.value.chartType.value,
                      notes: e.value.note,
                    };
                  });
                  setDietPlan((pre) => ({
                    ...pre,
                    ...e.value.dietPlan,
                    weekPlan: e.value.weekPlan,
                    chartType: e.value.chartType,
                    notes: e.value.note,
                  }));
                  setNote(e.value.note);
                }}
              />
            </SuiBox>
          </Grid>

          {!editValue && mode !== "view" && (
            <Grid item xs={12} md={2}>
              <SuiBox lineHeight={1} mt={3}>
                <SuiTypography mb={1} variant="h6">
                  Clone From
                </SuiTypography>
                <CustomeDatepicker
                  selectedDate={selectedDate}
                  onChangeDate={onChangeDate}
                  includeDates={includeDates}
                  placeholder={"Clone diet chart"}
                />
              </SuiBox>
            </Grid>
          )}

          {dietLoader && (
            <Grid
              item
              mb={1}
              xs={12}
              md={1}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <SuiBox>
                <TailSpin color="#17c1e8" height={30} width={30} />
              </SuiBox>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3}>
          {timeline.map((item, index) => (
            <Grid item xs={12} lg={12} key={index}>
              <RecipeChart
                recipeList={recipeList}
                readOnly={mode === "view"}
                defaultHandler={defaultHandlerv1}
                title={item.title}
                chartId={item.id}
                dietChart={dietPlan[item.id]}
                setDietPlan={setDietPlan}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                open={open}
                recipeLoader={recipeLoader}
                setScrollToTop={setScrollToTop}
              />
            </Grid>
          ))}

          <Grid item xs={12} lg={4}>
            <SuiBox
              p={2}
              style={{
                border: "1px solid #D2D6DA",
                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.05)",
                borderRadius: 8,
              }}
            >
              <SuiBox>
                <SuiTypography
                  style={{ textDecorationLine: "underline" }}
                  mb={1}
                  variant="h6"
                >
                  Notes
                </SuiTypography>
              </SuiBox>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1} mt={3}>
                    <SuiTypography mb={1} variant="h6">
                      Some Notes for Client
                    </SuiTypography>
                    <NoteInput
                      disabled={mode === "view"}
                      note={note}
                      readOnly={mode === "view"}
                      setNote={setNote}
                      errorMessage={errorMessage}
                      selectedItem={selectedItem}
                      setErrorMessage={setErrorMessage}
                      setDietPlan={setDietPlan}
                    />
                  </SuiBox>
                </Grid>

                {mode !== "view" && (
                  <Grid item xs={12} mx={7}>
                    <CustomButton
                      color="dark"
                      variant="gradient"
                      onClick={submitHandler}
                      fullWidth
                      width={180}
                      disabled={loader || !open}
                      style={{
                        marginBottom: 10,
                        height: 40,
                        width: 230,
                        padding: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      title={
                        selectedItem && !selectedDate && !selectedTemplate.label
                          ? "Edit Data"
                          : "Add Data"
                      }
                      loader={loader}
                    />
                  </Grid>
                )}
              </Grid>
            </SuiBox>

            <SuiBox
              p={2}
              style={{
                marginTop: 20,
                border: "1px solid #D2D6DA",
                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.05)",
                borderRadius: 8,
              }}
            >
              <SuiTypography variant="h6">
                Total calories: {totalCalories?.toFixed(2)}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
