import SuiBox from "components/SuiBox";
import React, { useState } from "react";
import EditProfileModal from "./edit-profile";
import SuiTypography from "components/SuiTypography";
import BasicDetails from "./profile-section/basic";
import PersonalDetails from "./profile-section/personal";
import PlanDetails from "./profile-section/plan";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import moment from "moment";
import AddPlanModal from "./edit-profile/planModal";
import ClientT_C from "./profile-section/t&c";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/features/authSlice";
import { getTermsAndConditions } from "store/features/authSlice";
import { AddClientTerms } from "store/features/clients";
import { ClientSelector } from "store/features/clients";

function Profile({ client, serviceOptions }) {
  const dispatch = useDispatch();

  const { termsAndConditions } = useSelector(userSelector);
  const { isFetching } = useSelector(ClientSelector);

  const [selectedData, setSelectedData] = useState();
  const [clientData, setClientData] = useState();
  const [stepVal, setStepVal] = useState(null);
  const [isClientTermsAccepted, setIsClientTermsAccepted] = useState(false);

  useEffect(() => {
    if (clientData) {
      dispatch(getTermsAndConditions({ uid: clientData.adminId }));
    }
    if (clientData?.isClientTermsAccepted) {
      setIsClientTermsAccepted(true);
    }
  }, [dispatch, clientData]);

  const handleCloseModal = () => {
    setSelectedData(null);
    setPlanInfoModal(false);
    setPersonalInfoModal(false);
  };

  useEffect(() => {
    if (client) {
      const { step } = client;
      if (step) {
        setStepVal(step);
      } else {
        setStepVal(null);
      }
    }
    setClientData(client);
  }, [client]);

  const [activeService, setActiveService] = useState(null);

  useEffect(() => {
    if (clientData && clientData.services && clientData.services.length > 0) {
      const today = moment().startOf("day");

      const activeServiceFound = clientData.services.find((service) => {
        const startDate = moment(service.startDate);
        const completionDate = moment(service.completionDate);
        return today.isBetween(startDate, completionDate, null, "[]");
      });

      if (activeServiceFound) {
        setActiveService({
          ...activeServiceFound,
          referredBy: clientData?.referredBy,
        });
      } else {
        setActiveService({
          ...clientData.services[clientData.services.length - 1],
          referredBy: clientData?.referredBy,
        });
      }
    }
  }, [clientData]);

  const [personalInfoModal, setPersonalInfoModal] = useState(false);
  const editPersonalInfo = () => {
    setPersonalInfoModal(true);
  };

  const [planInfoModal, setPlanInfoModal] = useState(false);
  const addPlanInfo = () => {
    setPlanInfoModal(true);
  };

  const termsSubmitHandler = async () => {
    await dispatch(
      AddClientTerms({ id: clientData.id, isClientTermsAccepted })
    );
  };

  return (
    <SuiBox overflow="hidden" minWidth="250px">
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <SuiTypography variant="h5" style={{ color: "#17C1E8" }}>
          Profile Details
        </SuiTypography>
      </SuiBox>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <BasicDetails client={clientData} />
          <ClientT_C
            termsAndConditions={termsAndConditions}
            setIsClientTermsAccepted={setIsClientTermsAccepted}
            isClientTermsAccepted={isClientTermsAccepted}
            termsSubmitHandler={termsSubmitHandler}
            loader={isFetching}
            clientData={clientData}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <PersonalDetails
            stepVal={stepVal}
            client={clientData}
            onHandler={editPersonalInfo}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <PlanDetails
            activeService={activeService}
            stepVal={stepVal}
            onHandler={addPlanInfo}
          />
        </Grid>
      </Grid>

      <EditProfileModal
        open={personalInfoModal}
        clientId={client?.id}
        stepVal={stepVal}
        setStepVal={setStepVal}
        setClientData={setClientData}
        selectedData={selectedData}
        setOpenModal={setPersonalInfoModal}
        handleCloseModal={handleCloseModal}
      />

      <AddPlanModal
        open={planInfoModal}
        serviceOptions={serviceOptions}
        clientId={client?.id}
        stepVal={stepVal}
        setStepVal={setStepVal}
        setClientData={setClientData}
        selectedData={selectedData}
        setOpenModal={setPlanInfoModal}
        handleCloseModal={handleCloseModal}
      />
    </SuiBox>
  );
}

export default Profile;
