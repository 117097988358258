import React, { useState, useEffect, useCallback } from "react";
import { dailyIntakeAndRecallTableColumns } from "utils/tablesHeaderContent";
import { dailyIntakeAndRecallRow } from "containers/clientDetails/sub-components/common/dailyIntakeAndRecallTableRows";
import AdminClientInfoCard from "containers/clientDetails/sub-components/card/adminClientCard";
import DefaultModal from "containers/clientDetails/sub-components/modalContainer/default";
import RecallModal from "containers/clientDetails/sub-components/modal/dietrecall";
import { headerDesc } from "utils/helper";
import { timestampConversion } from "utils/common";
import moment from "moment";
import * as XLSX from "xlsx";

function DietRecallTable({
  id,
  setSelectedClient,
  dietRecall,
  title,
  deleteHandler,
}) {
  const [rows, setRows] = useState([]);
  const columns = [...dailyIntakeAndRecallTableColumns];
  const [newRecallModal, setNewRecallModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState("view");

  const addHandler = useCallback(() => {
    setNewRecallModal(true);
    setSelectedItem(null);
    setMode("create");
  }, []);

  const editHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("edit");
    setNewRecallModal(true);
  }, []);

  const viewHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("view");
    setNewRecallModal(true);
  }, []);

  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    let dietRecallArray = dietRecall.slice();

    dietRecallArray.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.createdAt);
      const b_timestamp = convertTimestamp(b?.createdAt);
      return b_timestamp - a_timestamp;
    });

    setRows(
      dailyIntakeAndRecallRow(
        dietRecallArray,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [dietRecall, editHandler, viewHandler]);

  const downloadBtnClickHandler = () => {
    let updatedData = dietRecall.map((item, index) => {
      let createdAt = item?.createdAt?.seconds
        ? moment(timestampConversion(item?.createdAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : "N/A";

      const meals = [
        "earlyMorning",
        "midMorning",
        "brunch",
        "lunch",
        "midMeal",
        "evening",
        "postLunch",
        "beforeDinner",
        "dinner",
        "postDinner",
        "lateNight",
      ];

      let mealData = meals.map((meal) => {
        return `${item?.[meal]?.clientAte ?? "N/A"} at ${
          item?.[meal]?.time ?? "N/A"
        }`;
      });

      return [
        index + 1,
        createdAt,
        item?.dietType ?? "N/A",
        item?.frequencyOfEating ?? "N/A",
        item?.waterIntake ?? "N/A",
        ...mealData,
      ];
    });

    let excelData = [
      [
        "ID",
        "Created At",
        "Diet Type",
        "Frequency of Eating",
        "Water Intake",
        "Early Morning",
        "Mid Morning",
        "Brunch",
        "Lunch",
        "Mid Meal",
        "Evening",
        "Post Lunch",
        "Before Dinner",
        "Dinner",
        "Post Dinner",
        "Late Night",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Diet & Meal Data");
    XLSX.writeFile(workbook, "Dietary_recall.xlsx");
  };

  return (
    <>
      <AdminClientInfoCard
        title={title}
        onClickBtnHandler={addHandler}
        columns={columns}
        rows={rows}
        oneTimeEntry={true}
        downloadButton
        downloadBtnClickHandler={downloadBtnClickHandler}
      />
      <DefaultModal
        open={newRecallModal}
        {...headerDesc(mode, "Manage Diet Recall", selectedItem)}
        title={"Manage Diet Recall"}
        description={`You can ${selectedItem ? "edit" : "add"} details below.`}
        handleCloseModal={() => {
          setNewRecallModal(false);
          setSelectedItem(null);
        }}
        mode={mode}
        setMode={setMode}
      >
        <RecallModal
          id={id}
          persona="admin"
          mode={mode}
          open={newRecallModal}
          setSelectedClient={setSelectedClient}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleCloseModal={() => {
            setNewRecallModal(false);
            setSelectedItem(null);
          }}
        />
      </DefaultModal>
    </>
  );
}

export default DietRecallTable;
