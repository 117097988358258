import { useEffect, useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import ServiceClientList from "./sub-components/ServiceClient";
import ProductClientList from "./sub-components/ProductClient";

// import ServiceClientModal from './sub-components/modals/ServiceClientModal'
import EditModal from "./sub-components/modals/EditModal";
import EditSoldModal from "./sub-components/modals/product";
import FamilyModal from "./sub-components/modals/family";
import ConsultationClientModal from "./sub-components/modals/ConsultationClientModal";

import { useDispatch, useSelector } from "react-redux";
import {
  ClientSelector,
  getClients,
  clearState,
  deleteDocument,
  updateFilterState,
} from "store/features/clients";

import { toast } from "react-toastify";

import { getUpDownStat } from "utils/common";
import { StatSelector, statisticState } from "store/features/statistics.js";
import { monthWiseClientsCount } from "firebaseConfig";

import { userSelector } from "store/features/authSlice";
import {
  DashboardSelector,
  getServiceInventory,
  getProductInventory,
  getConsultationServices,
  clearState as dashboardClearState,
} from "store/features/dashboard";
import DeleteModal from "containers/DeleteModal";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "firebaseConfig";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "@mui/material";
import ConsultantServiceClientList from "./sub-components/ConsultantClient";
import moment from "moment";

import PaymentModal from "./sub-components/modals/consultation";
import AddClientStepper from "./sub-components/modals/AddClientStepper";
import ServiceViewModal from "./sub-components/modals/view/service";
import ProductViewModal from "./sub-components/modals/view/product";
import ConsultationViewModal from "./sub-components/modals/view/consultation";
import { getUserLink } from "utils/helper";
import {
  employeeSelector,
  getEmployees,
  clearState as employeeClearState,
} from "store/features/employee";
import { getIntlPhoneNumber } from "store/features/authSlice";

function Client() {
  const { uid, name, logo, intlPhoneNumber } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [MiniCardData, setMiniCardData] = useState([]);
  const { clients, isError, isSuccess, message, isFetching, filterState } =
    useSelector(ClientSelector);

  const {
    employees,
    isError: employeeIsError,
    isSuccess: employeeIsSuccess,
    message: employeeIsMessage,
    isFetching: employeeIsFetching,
  } = useSelector(employeeSelector);

  //for service package list
  const {
    services,
    products,
    consultation,
    isSuccess: isSuccess_,
    isError: isError_,
    message: message_,
  } = useSelector(DashboardSelector);

  useEffect(() => {
    if (employeeIsSuccess) {
      toast.success(employeeIsMessage);
      dispatch(employeeClearState());
      onDeleteSuccess();
    }
    if (employeeIsError) {
      toast.error(employeeIsMessage);
      setLoader(false);
      dispatch(employeeClearState());
    }

    if (employeeIsFetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [
    employeeIsSuccess,
    employeeIsMessage,
    dispatch,
    employeeIsError,
    employeeIsFetching,
  ]);

  useEffect(() => {
    if (isSuccess_) {
      dispatch(dashboardClearState());
    }
    if (isError_) {
      toast.error(message_);
      dispatch(dashboardClearState());
    }
  }, [isSuccess_, message_, dispatch, isError_]);

  useEffect(() => {
    if (isSuccess_) {
      dispatch(dashboardClearState());
    }
    if (isError_) {
      toast.error(message_);
      dispatch(dashboardClearState());
    }
  }, [isSuccess_, message_, dispatch, isError_]);

  const {
    lastMonthClients,
    currentMonthClients,
    clientCount,
    lastMonthServiceSaleCount,
    currentMonthServiceSaleCount,
    currentMonthProductSaleCount,
    lastMonthProductSaleCount,
    balance,
  } = useSelector(StatSelector); //navbar stats

  const [selectedItem, setSelectedItem] = useState(null); //for selecting product for allocation

  //gets inventory and client lists
  const getData = useCallback(async () => {
    setLoader(true);
    try {
      await dispatch(getProductInventory({ uid }));
      await dispatch(getServiceInventory({ uid }));
      await dispatch(getConsultationServices({ uid }));
      await dispatch(getClients({ uid }));
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }, [uid, dispatch]);

  useEffect(() => {
    if (uid) {
      getData();
    }
  }, [uid, getData]);

  useEffect(() => {
    dispatch(getEmployees({ uid }));
  }, [dispatch, uid]);

  const [productClients, setProductClients] = useState([]);
  const [serviceClients, setServiceClients] = useState([]);
  const [consultationClients, setConsultationClients] = useState([]);
  const [intlPhoneNumberArray, setIntlPhoneNumberArray] = useState([]);

  //for listing family data
  const [familyArray, setFamilyArray] = useState([]);
  useEffect(() => {
    let serviceData = [],
      productData = [],
      consultationData = [],
      familyData = [],
      familyObj = {},
      mailLink;

    clients.map((item) => {
      let purchasedAt;

      if (item.familyName && !familyObj[item.familyName]) {
        familyObj[item.familyName] = 1;
        familyData.push({ label: item.familyName, value: item.familyName });
      }

      if (item?.products?.length > 0) {
        let product_ = item.products;

        product_ = product_.map((item_) => {
          purchasedAt = item_.purchasedAt;
          mailLink = getUserLink(uid, item?.id);
          return {
            ...item_,
            productNo: item_.id,
            familyName: item.familyName,
            name: item.name,
            adminId: item.adminId,
            id: item.id,
            email: item.email,
            status: item.status,
            phoneNumber: item.phoneNumber,
            preference: item.preference,
            customerId: item.customerId,
            purchasedAt,
            mailLink: mailLink,
            isClientTermsAccepted: item.isClientTermsAccepted,
          };
        });

        productData.push(...product_);
      }

      if (item?.services?.length > 0) {
        let service_ = item.services;
        mailLink = getUserLink(uid, item?.id);

        service_ = service_.map((item_) => {
          return {
            ...item_,
            countryCode: item.countryCode ?? {
              label: "(+91) India",
              value: "+91",
              key: "IN",
            },
            step: item.step ?? null,
            serviceNo: item_.id,
            name: item.name,
            familyName: item.familyName,
            adminId: item.adminId,
            id: item.id,
            email: item.email,
            phoneNumber: item.phoneNumber,
            address: item.address,
            gender: item.gender,
            dob: item.dob,
            anniversary: item.anniversary,
            preference: item.preference,
            occupation: item.occupation,
            age: item.age,
            leadFrom: item.leadFrom,
            referredBy: item.referredBy,
            status: item.status,
            customerId: item.customerId,
            notes: item.notes,
            mailLink,
            isClientTermsAccepted: item.isClientTermsAccepted,
          };
        });

        serviceData.push(...service_);
      }

      if (item?.consultation?.length > 0) {
        let consultation_ = item.consultation;
        mailLink = getUserLink(uid, item?.id);

        consultation_ = consultation_.map((item_) => {
          return {
            ...item_,
            countryCode: item.countryCode ?? {
              label: "(+91) India",
              value: "+91",
              key: "IN",
            },
            serviceNo: item_.id,
            name: item.name,
            familyName: item.familyName,
            adminId: item.adminId,
            id: item.id,
            email: item.email,
            phoneNumber: item.phoneNumber,
            address: item.address,
            gender: item.gender,
            dob: item.dob,
            anniversary: item.anniversary,
            preference: item.preference,
            occupation: item.occupation,
            age: item.age,
            leadFrom: item.leadFrom,
            referredBy: item.referredBy,
            status: item.status,
            customerId: item.customerId,
            notes: item.notes,
            mailLink,
            isClientTermsAccepted: item.isClientTermsAccepted,
          };
        });

        consultationData.push(...consultation_);
      }
      return item;
    });

    serviceData.sort(function (a, b) {
      let createdAt1 = a?.purchasedAt;
      createdAt1 = createdAt1?.seconds
        ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
        : createdAt1;

      let createdAt2 = b?.purchasedAt;
      createdAt2 = createdAt2?.seconds
        ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
        : createdAt2;
      return new Date(createdAt2) - new Date(createdAt1);
    });

    productData.sort(function (a, b) {
      let createdAt1 = a.purchasedAt;
      createdAt1 = createdAt1.seconds
        ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
        : createdAt1;

      let createdAt2 = b.purchasedAt;
      createdAt2 = createdAt2.seconds
        ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
        : createdAt2;
      return new Date(createdAt2) - new Date(createdAt1);
    });

    consultationData.sort(function (a, b) {
      let createdAt1 = a.createdAt;
      createdAt1 = createdAt1.seconds
        ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
        : createdAt1;

      let createdAt2 = b.createdAt;
      createdAt2 = createdAt2.seconds
        ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
        : createdAt2;
      return new Date(createdAt2) - new Date(createdAt1);
    });

    setProductClients(productData);
    setServiceClients(serviceData);
    setConsultationClients(consultationData);
    setFamilyArray(familyData);
  }, [clients]);

  useEffect(() => {
    dispatch(getIntlPhoneNumber());
  }, []);

  useEffect(() => {
    if (intlPhoneNumber) {
      const updateIntlNumber = intlPhoneNumber.data.map((item, index) => {
        return {
          label: `${
            item.dial_code.length === 2
              ? `(${item.dial_code})`.padEnd(10)
              : item.dial_code.length === 3
              ? `(${item.dial_code})`.padEnd(9)
              : item.dial_code.length === 4
              ? `(${item.dial_code})`.padEnd(8)
              : `(${item.dial_code})`
          } ${
            item?.name?.length > 15
              ? `${item?.name?.slice(0, 15)}...`
              : item?.name
          }`,
          value: item.dial_code,
          key: item.code,
          phoneLength: item.phoneLength,
        };
      });

      setIntlPhoneNumberArray(updateIntlNumber);
    }
  }, [intlPhoneNumber]);

  // gets stats of topbar
  useEffect(() => {
    (async () => {
      let balance_ = 0;
      let serviceClientCount = 0;
      const today = moment();

      clients.map((item) => {
        let active = false;

        if (item.services) {
          item?.services.map((service) => {
            const isBetweenDates = today.isBetween(
              service.startDate,
              service.completionDate,
              null,
              "[]"
            );
            if (isBetweenDates) active = true;
            balance_ +=
              parseFloat(service.totalAmount) -
              parseFloat(service.discount ?? 0) -
              parseFloat(service.amountPaid);
            return service;
          });
        }

        if (item.consultation) {
          item?.consultation.map((service) => {
            balance_ +=
              parseFloat(service.totalAmount ?? 0) -
              parseFloat(service.discount ?? 0) -
              parseFloat(service.amountPaid ?? 0);
            return service;
          });
        }

        if (item?.products) {
          item?.products?.map((product) => {
            balance_ +=
              parseInt(product.quantity) *
                (
                  parseFloat(product?.sellingPriceWOT) -
                  parseFloat(product?.discount ?? 0) +
                  parseFloat(
                    parseFloat(product?.sellingPriceWOT ?? 0) *
                      (parseFloat(product?.gst ?? 0) / 100)
                  )
                ).toFixed(2) -
              parseFloat(product.amountPaid ?? 0);
            return product;
          });
        }
        if (active) serviceClientCount++;

        return item;
      });

      let {
        lastMonthClients,
        currentMonthClients,
        lastMonthServiceSaleCount,
        currentMonthServiceSaleCount,
        currentMonthProductSaleCount,
        lastMonthProductSaleCount,
      } = await monthWiseClientsCount(uid);

      let payload = {
        lastMonthClients,
        currentMonthClients,
        clientCount: serviceClientCount,
        lastMonthServiceSaleCount,
        currentMonthServiceSaleCount,
        currentMonthProductSaleCount,
        lastMonthProductSaleCount,
        balance: balance_,
      };
      dispatch(statisticState(payload));
    })();
  }, [dispatch, uid, clients]);

  useEffect(() => {
    let MiniCardData_ = [
      {
        title: "Total Active Clients",
        count: clientCount,
        percent: {
          ...getUpDownStat(lastMonthClients, currentMonthClients),
        },
        icon: {
          color: "info",
          component: "person_2",
          para: "These are your total active service clients",
        },
      },
      {
        title: "Service Sales This Month",
        count: currentMonthServiceSaleCount,
        percent: {
          ...getUpDownStat(
            lastMonthServiceSaleCount,
            currentMonthServiceSaleCount
          ),
        },
        icon: {
          color: "info",
          component: "paid",
          para: "This is your sales from services for the current month ",
        },
        onTileClickHandler: () => {
          dispatch(updateFilterState({ state: "SERVICE" }));
        },
      },
      {
        title: "Product Sales This Month",
        count: currentMonthProductSaleCount,
        percent: {
          ...getUpDownStat(
            lastMonthProductSaleCount,
            currentMonthProductSaleCount
          ),
        },
        icon: {
          color: "info",
          component: "task_alt",
          para: "This is your product sales for the current month",
        },
        onTileClickHandler: () => {
          dispatch(updateFilterState({ state: "PRODUCT" }));
        },
      },
      {
        title: "Balance To Be Collected",
        count: <span style={{ color: "red" }}>{balance?.toFixed(2)}</span>,

        icon: {
          color: "info",
          component: "inventory_2",
          para: "Overall balance to be collected from Services & Product sales",
        },
      },
    ];
    setMiniCardData(MiniCardData_);
  }, [
    lastMonthClients,
    currentMonthClients,
    clientCount,
    currentMonthProductSaleCount,
    lastMonthProductSaleCount,
    currentMonthServiceSaleCount,
    lastMonthServiceSaleCount,
    balance,
    dispatch,
  ]);

  const [newServiceClient, setNewServiceClient] = useState(false);
  const [newConsultationServiceClient, setNewConsultationServiceClient] =
    useState(false);

  const [editServiceClient, setEditServiceClient] = useState(false);
  const [editConsultationClient, setEditConsultationClient] = useState(false);

  //for deleting product
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteHandler = (clientId, persona, id) => {
    dispatch(deleteDocument({ persona, clientId, id }));
  };

  const onDeleteSuccess = useCallback(() => {
    if (deleteModal) {
      setDeleteModal(false);
    }
  }, [deleteModal]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      onDeleteSuccess();
      dispatch(clearState());
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }
  }, [isSuccess, message, dispatch, isError, isFetching, onDeleteSuccess]);

  const [newSellModal, setNewSellModal] = useState(false);

  const editProductHandler = async (item) => {
    try {
      setLoader(true);
      const ref = doc(firestore, "product", item.productId);
      const snapshot = await getDoc(ref);
      const { availableQuantity } = snapshot.data();
      setSelectedItem({ ...item, availableQuantity });
      setNewSellModal(true);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      toast.error("Something went wrong, retry");
    }
  };

  const [familyModal, setFamilyModal] = useState(false);
  const [familyMemberArray, setFamilyMemberArray] = useState({
    list: [],
    name: null,
  });
  const filterFamilyMember = (name) => {
    let familyArray = [];

    for (let i = 0; i < clients.length; i++) {
      if (clients[i].familyName === name) {
        familyArray.push({
          label: `${clients[i].name} (+91 ${clients[i].phoneNumber})`,
          value: clients[i].id,
        });
      }
    }

    setFamilyMemberArray((e) => {
      e = {
        list: familyArray,
        name,
      };
      return e;
    });
    setFamilyModal(true);
  };

  const [mode, setMode] = useState("view");
  const [viewModal, setViewModal] = useState(false);
  const addHandler = useCallback((type) => {
    setMode("add");
    switch (type) {
      case "service":
        setNewServiceClient(true);
        break;
      case "product":
        break;
      case "consultation":
        setNewConsultationServiceClient(true);
        break;
      default:
        break;
    }
  }, []);

  const editHandler = useCallback((item, type) => {
    setMode("edit");
    switch (type) {
      case "service":
        setEditServiceClient(true);
        break;
      case "product":
        break;
      case "consultation":
        setEditConsultationClient(true);
        break;
      default:
        break;
    }
    setSelectedItem(item);
  }, []);

  const viewHandler = useCallback((item, type) => {
    setSelectedItem(item);
    switch (type) {
      case "service":
        setViewModal("service");
        break;
      case "product":
        setViewModal("product");
        break;
      case "consultation":
        setViewModal("consultation");
        break;
      default:
        break;
    }
    setMode("view");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            {MiniCardData.map((item, index) => (
              <Grid item xs={12} sm={6} xl={3} key={index}>
                <MiniStatisticsCard
                  title={{ text: item?.title }}
                  count={loader ? "" : item?.count}
                  onClickHandler={
                    item.onTileClickHandler
                      ? item.onTileClickHandler
                      : () => null
                  }
                  percentage={
                    loader
                      ? {}
                      : {
                          color: item?.percent?.color,
                          text: item?.percent?.text,
                        }
                  }
                  icon={{
                    color: item?.icon?.color,
                    component: item?.icon?.component,
                    para: item?.icon?.para,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </SuiBox>

        <Tabs>
          <TabList>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={2} color="auto">
                <span>Clients list (Services)</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={"Below are the clients who have taken your services"}
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">help</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={2} color="auto">
                <span>Clients list (Products)</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      "These are the clients whom you have sold your products"
                    }
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">{"help"}</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={2} color="auto">
                <span>Clients list (Consultation)</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      "These are the clients whom you have provided your consultation services"
                    }
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">{"help"}</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
          </TabList>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ServiceClientList
                    clients={serviceClients}
                    adminId={uid}
                    services={services}
                    filterState={filterState}
                    isSuccess={isSuccess}
                    viewHandler={viewHandler}
                    addHandler={() => {
                      setMode("create");
                      setNewServiceClient(true);
                    }}
                    editHandler={(item) => {
                      setMode("edit");
                      setSelectedItem(item);
                      setEditServiceClient(true);
                    }}
                    deleteHandler={(id, name, serviceId) => {
                      setSelectedItem({
                        id,
                        name,
                        persona: "service",
                        ID: serviceId,
                      });
                      setDeleteModal(true);
                    }}
                    loader={loader}
                    familyArray={familyArray}
                    filterFamilyMember={filterFamilyMember}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>
          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ProductClientList
                    clients={productClients}
                    products={products}
                    filterState={filterState}
                    isSuccess={isSuccess}
                    viewHandler={viewHandler}
                    onSelectHandler={() => null}
                    editHandler={(item) => {
                      setMode("edit");
                      editProductHandler(item);
                    }}
                    deleteHandler={(id, name, productId) => {
                      setSelectedItem({
                        id,
                        name,
                        persona: "product",
                        ID: productId,
                      });
                      setDeleteModal(true);
                    }}
                    loader={loader}
                    familyArray={familyArray}
                    filterFamilyMember={filterFamilyMember}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ConsultantServiceClientList
                    clients={consultationClients}
                    services={consultation}
                    filterState={filterState}
                    isSuccess={isSuccess}
                    addHandler={() => addHandler("consultation")}
                    viewHandler={viewHandler}
                    editHandler={editHandler}
                    deleteHandler={(id, name, consultationId) => {
                      setSelectedItem({
                        id,
                        name,
                        persona: "consultation",
                        ID: consultationId,
                      });
                      setDeleteModal(true);
                    }}
                    loader={loader}
                    familyArray={familyArray}
                    filterFamilyMember={filterFamilyMember}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>
        </Tabs>
      </SuiBox>
      <AddClientStepper
        open={newServiceClient}
        setOpenModal={setNewServiceClient}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
        services={services}
        uid={uid}
        name={name}
        logo={logo}
        clients={clients}
        employees={employees}
        handleCloseModal={() => {
          setNewServiceClient(false);
        }}
        familyArray={familyArray}
        intlPhoneNumberArray={intlPhoneNumberArray}
      />
      <ConsultationClientModal
        open={newConsultationServiceClient}
        setOpenModal={setNewConsultationServiceClient}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
        services={consultation}
        uid={uid}
        clients={clients}
        handleCloseModal={() => {
          setNewConsultationServiceClient(false);
        }}
        familyArray={familyArray}
        employees={employees}
        intlPhoneNumberArray={intlPhoneNumberArray}
      />
      <EditModal
        open={editServiceClient}
        setOpenModal={setEditServiceClient}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handleCloseModal={() => {
          setEditServiceClient(false);
        }}
        familyArray={familyArray}
        mode={mode}
        viewHandler={viewHandler}
        editHandler={editHandler}
        setViewModal={setViewModal}
        setEditServiceClient={setEditServiceClient}
        employees={employees}
        intlPhoneNumberArray={intlPhoneNumberArray}
      />
      <FamilyModal
        open={familyModal}
        setOpenModal={setFamilyModal}
        familyMemberArray={familyMemberArray}
        handleCloseModal={() => {
          setTimeout(() => {
            setFamilyMemberArray({ list: [], name: null });
          }, 500);
          setFamilyModal(false);
        }}
      />
      {deleteModal && (
        <DeleteModal
          isFetching={isFetching}
          onDeleteHandler={deleteHandler}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
      <EditSoldModal
        open={newSellModal}
        setOpenModal={setNewSellModal}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        loader={loader}
        setLoader={setLoader}
        message={message}
        clientId={selectedItem?.id}
        handleCloseModal={() => {
          setTimeout(() => {
            setSelectedItem(null);
          }, 500);
          setNewSellModal(false);
        }}
        selectedItem={selectedItem}
        viewHandler={viewHandler}
        editHandler={editHandler}
        setViewModal={setViewModal}
        mode={mode}
      />
      <PaymentModal
        open={editConsultationClient}
        id={selectedItem?.id}
        services={consultation}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setSelectedClient={setConsultationClients}
        handleCloseModal={() => {
          setEditConsultationClient(false);
        }}
        viewHandler={viewHandler}
        editHandler={editHandler}
        setViewModal={setViewModal}
        mode={mode}
        setEditConsultationClient={setEditConsultationClient}
        employees={employees}
      />
      <ServiceViewModal
        open={viewModal && viewModal === "service"}
        mode={mode}
        item={selectedItem}
        handleCloseModal={() => {
          setTimeout(() => {
            setSelectedItem(null);
          }, 500);
          setViewModal(false);
        }}
        viewHandler={viewHandler}
        editHandler={editHandler}
        setEditServiceClient={setEditServiceClient}
        setViewModal={setViewModal}
      />
      <ProductViewModal
        open={viewModal && viewModal === "product"}
        mode={mode}
        item={selectedItem}
        handleCloseModal={() => {
          setTimeout(() => {
            setSelectedItem(null);
          }, 500);
          setViewModal(false);
        }}
        setNewSellModal={setNewSellModal}
        viewHandler={viewHandler}
        editHandler={editHandler}
        setViewModal={setViewModal}
      />
      <ConsultationViewModal
        open={viewModal && viewModal === "consultation"}
        mode={mode}
        formdata={selectedItem}
        handleCloseModal={() => {
          setTimeout(() => {
            setSelectedItem(null);
          }, 500);
          setViewModal(false);
        }}
        viewHandler={viewHandler}
        editHandler={editHandler}
        setViewModal={setViewModal}
      />
    </DashboardLayout>
  );
}

export default Client;
