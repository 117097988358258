import React, { useState, useEffect, useCallback } from "react";
import { biochemicalHealthRecordsRows } from "containers/clientDetails/sub-components/common/biochemicalHealthRecordsRows";
import { biochemicalTableColumns } from "utils/tablesHeaderContent";
import AdminClientInfoCard from "containers/clientDetails/sub-components/card/adminClientCard";
import BiochemicalModal from "containers/clientDetails/sub-components/modal/biochemical";
import DefaultModal from "containers/clientDetails/sub-components/modalContainer/default";
import { headerDesc } from "utils/helper";
import moment from "moment";
import { timestampConversion } from "utils/common";
import * as XLSX from "xlsx";

function BiochemicalHealthRecords({
  id,
  biochemicals,
  title,
  setSelectedClient,
  deleteHandler,
  isSuccess,
  isError,
  isFetching,
  loader,
  setLoader,
  message,
}) {
  const [rows, setRows] = useState([]);
  const [newBiochemicalModal, setNewBiochemicalModal] = useState(false);
  const columns = [...biochemicalTableColumns];
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState("view");

  const addHandler = useCallback(() => {
    setSelectedItem(null);
    setMode("create");
    setNewBiochemicalModal(true);
  }, []);

  const editHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("edit");
    setNewBiochemicalModal(true);
  }, []);

  const viewHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("view");
    setNewBiochemicalModal(true);
  }, []);

  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    let biochemicalArray = biochemicals.slice();

    biochemicalArray.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.timestamp);
      const b_timestamp = convertTimestamp(b?.timestamp);
      return b_timestamp - a_timestamp;
    });

    setRows(
      biochemicalHealthRecordsRows(
        biochemicalArray,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [biochemicals, editHandler, viewHandler]);

  const downloadBtnClickHandler = () => {
    let updatedData = biochemicals.map((item, index) => {
      let time1 = item?.updatedAt?.seconds
        ? moment(timestampConversion(item?.updatedAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : moment(item?.updatedAt).local().format("MMMM Do YYYY, h:mm a");

      return [
        index + 1,
        item?.doctorName ?? "N/A",
        item?.vitamin ?? "N/A",
        item?.skippedMeal ?? "N/A",
        item?.hb ?? "N/A",
        item?.allergicFrom?.join(", ") ?? "N/A",
        item?.smokeAmount ?? "N/A",
        item?.crp ?? "N/A",
        item?.obesityInFamily ?? "N/A",
        item?.softDrinks ?? "N/A",
        item?.sodium ?? "N/A",
        item?.potassium ?? "N/A",
        item?.alcohol ?? "N/A",
        item?.calcium ?? "N/A",
        item?.vitaminb12 ?? "N/A",
        item?.uricAcid ?? "N/A",
        item?.fastFoodConsumption ?? "N/A",
        item?.bloodSugar ?? "N/A",
        item?.wbc ?? "N/A",
        item?.cholestrol ?? "N/A",
        item?.bilirubin ?? "N/A",
        item?.rashesOnSkin ?? "N/A",
        item?.bloodUreaNitrogen ?? "N/A",
        item?.looseWeightProgramme ?? "N/A",
        item?.triglycerides ?? "N/A",
        item?.hbaic ?? "N/A",
        item?.smoke ?? "N/A",
        item?.waterIntake ?? "N/A",
        item?.sleepHours ?? "N/A",
        item?.operated ?? "N/A",
        item?.observeFasting ?? "N/A",
        item?.creatinine ?? "N/A",
        item?.bloodUrea ?? "N/A",
        item?.foodYouLike ?? "N/A",
        item?.weightTimePeriod ?? "N/A",
        item?.sgot ?? "N/A",
        item?.abg ?? "N/A",
        item?.ongoingMedication ?? "N/A",
        item?.sgpt ?? "N/A",
        item?.rbc ?? "N/A",
        item?.durationMediction ?? "N/A",
        item?.weightLost ?? "N/A",
        item?.foodYouDislike ?? "N/A",
        item?.anyOtherCondition ?? "N/A",
        time1, // Updated at timestamp
        item?.habitOfEating ?? "N/A",
        item?.isFastingObserve ?? "N/A",
        item?.ldl ?? "N/A",
        item?.heartiestMeal ?? "N/A",
        item?.lastPeriodDate ?? "N/A",
        item?.pp ?? "N/A",
        item?.hdl ?? "N/A",
        item?.t4 ?? "N/A",
        item?.sufferingFrom?.join(", ") ?? "N/A",
        item?.timestamp?.seconds
          ? moment(timestampConversion(item?.timestamp))
              .local()
              .format("MMMM Do YYYY, h:mm a")
          : "N/A",
        item?.isLooseWeightTried?.label ?? "N/A",
        item?.foodsYouEnjoy?.join(", ") ?? "N/A",
        item?.transferrin ?? "N/A",
        item?.vldl ?? "N/A",
        item?.allergicFoods ?? "N/A",
        item?.t3 ?? "N/A",
        item?.d3 ?? "N/A",
        item?.alcoholAmount ?? "N/A",
        item?.bp ?? "N/A",
      ];
    });

    let excelData = [
      [
        "ID",
        "DOCTOR NAME",
        "VITAMIN",
        "SKIPPED MEAL",
        "HB",
        "ALLERGIC FROM",
        "SMOKE AMOUNT",
        "CRP",
        "OBESITY IN FAMILY",
        "SOFT DRINKS",
        "SODIUM",
        "POTASSIUM",
        "ALCOHOL",
        "CALCIUM",
        "VITAMIN B12",
        "URIC ACID",
        "FAST FOOD CONSUMPTION",
        "BLOOD SUGAR",
        "WBC",
        "CHOLESTEROL",
        "BILIRUBIN",
        "RASHES ON SKIN",
        "BLOOD UREA NITROGEN",
        "LOOSE WEIGHT PROGRAMME",
        "TRIGLYCERIDES",
        "HBA1C",
        "SMOKE",
        "WATER INTAKE",
        "SLEEP HOURS",
        "OPERATED",
        "OBSERVE FASTING",
        "CREATININE",
        "BLOOD UREA",
        "FOOD YOU LIKE",
        "WEIGHT TIME PERIOD",
        "SGOT",
        "ABG",
        "ONGOING MEDICATION",
        "SGPT",
        "RBC",
        "DURATION MEDICATION",
        "WEIGHT LOST",
        "FOOD YOU DISLIKE",
        "ANY OTHER CONDITION",
        "UPDATED AT",
        "HABIT OF EATING",
        "IS FASTING OBSERVED",
        "LDL",
        "HEARTIEST MEAL",
        "LAST PERIOD DATE",
        "PP",
        "HDL",
        "T4",
        "SUFFERING FROM",
        "TIMESTAMP",
        "LOOSE WEIGHT TRIED",
        "FOODS YOU ENJOY",
        "TRANSFERRIN",
        "VLDL",
        "ALLERGIC FOODS",
        "T3",
        "D3",
        "ALCOHOL AMOUNT",
        "BP",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Diet_Lifestyle_Biochem_Assessment.xlsx");
  };

  return (
    <>
      <AdminClientInfoCard
        title={title}
        onClickBtnHandler={addHandler}
        columns={columns}
        rows={rows}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        loader={loader}
        setLoader={setLoader}
        message={message}
        downloadBtnClickHandler={downloadBtnClickHandler}
        downloadButton
      />

      <DefaultModal
        open={newBiochemicalModal}
        {...headerDesc(mode, "Dietary, Lifestyle & Biochemical", selectedItem)}
        handleCloseModal={() => {
          setNewBiochemicalModal(false);
          setSelectedItem(null);
        }}
        mode={mode}
        setMode={setMode}
      >
        <BiochemicalModal
          id={id}
          persona="admin"
          mode={mode}
          open={newBiochemicalModal}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setSelectedClient={setSelectedClient}
          handleCloseModal={() => {
            setNewBiochemicalModal(false);
            setSelectedItem(null);
          }}
        />
      </DefaultModal>
    </>
  );
}

export default BiochemicalHealthRecords;
