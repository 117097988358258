import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { ProductPurchasesRow } from "containers/clientDetails/sub-components/common/productPurchasesRow";
import ProductPurchaseStat from "../../sub-components/common/productStat.js";
import { productTableColumns } from "utils/tablesHeaderContent.js";
import AdminClientInfoCard from "containers/clientDetails/sub-components/card/adminClientCard.js";

function ProductPurchases({
  products,
  title,
  editHandler,
  deleteHandler,
  clientId,
  viewHandler,
  mailCheckHandler,
}) {
  const [rows, setRows] = useState([]);
  const columns = [...productTableColumns];

  useEffect(() => {
    setRows(
      ProductPurchasesRow(
        products,
        editHandler,
        deleteHandler,
        clientId,
        viewHandler,
        mailCheckHandler
      ) ?? []
    );
  }, [
    products,
    editHandler,
    clientId,
    deleteHandler,
    viewHandler,
    mailCheckHandler,
  ]);

  return (
    <Card>
      <AdminClientInfoCard
        title={title}
        statComponent={<ProductPurchaseStat products={products ?? []} />}
        columns={columns}
        rows={rows}
      />
    </Card>
  );
}

export default ProductPurchases;
