import SuiTypography from "components/SuiTypography";
import {
  calculateProductFinalPrice,
  calculateProductTotalBalance,
} from "utils/common";
import { IconButton } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import { globaleDateTimeFormatter } from "utils/helper";
import TableAction from "containers/table-action";

const reOpenModal = (familyName, filterFamilyMember) => {
  if (familyName !== undefined) {
    filterFamilyMember(familyName);
  }
};

export const ClientRows_ = (
  clientArray,
  navigate,
  onSelectHandler,
  editHandler,
  deleteHandler,
  filterFamilyMember,
  viewHandler
) => {
  const rows_ = clientArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.purchasedAt)}
        </SuiTypography>
      ),

      "Client Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: "#17C1E8",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/clients/${item.id}`);
          }}
        >
          {item?.name}
        </SuiTypography>
      ),

      "Family Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={
            item?.familyName
              ? {
                  color: "#17C1E8",
                  cursor: "pointer",
                }
              : {}
          }
          onClick={() => {
            reOpenModal(item?.familyName, filterFamilyMember);
          }}
        >
          {item?.familyName ?? "N/A"}
        </SuiTypography>
      ),

      // "Customer Id": (
      //   <SuiTypography variant="button" fontWeight="medium">
      //     {item.customerId ?? "N/A"}
      //   </SuiTypography>
      // ),
      "T&C Status": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: item.isClientTermsAccepted ? "#2ecc71" : "#FF0000" }}
        >
          {item.isClientTermsAccepted ? "Accepted" : "Not Accepted"}
        </SuiTypography>
      ),

      Phone: (
        <>
          <SuiTypography variant="button" fontWeight="medium">
            {item?.phoneNumber}
          </SuiTypography>
          <IconButton
            size="small"
            style={{ padding: "3px", marginLeft: "5px" }}
            onClick={() => {
              window.open(`https://wa.me/${item?.phoneNumber}`, "_blank");
            }}
          >
            <WhatsApp style={{ color: "#075e54" }} />
          </IconButton>
        </>
      ),

      Email: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.email}
        </SuiTypography>
      ),

      MRP: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.mrp ?? "N/A"}
        </SuiTypography>
      ),

      "Selling Price WOT": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.sellingPriceWOT ?? "N/A"}
        </SuiTypography>
      ),

      "Discount (INR)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.discount ?? "N/A"}
        </SuiTypography>
      ),

      "GST(%)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.gst}
        </SuiTypography>
      ),

      "Selling Price WT": (
        <SuiTypography variant="button" fontWeight="medium">
          {calculateProductFinalPrice(
            item.quantity,
            item.sellingPriceWOT,
            item.discount,
            item.gst
          )}
        </SuiTypography>
      ),

      Quantity: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.quantity}
        </SuiTypography>
      ),

      "Amount Paid": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.amountPaid ?? 0}
        </SuiTypography>
      ),

      Balance: (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: "red" }}
        >
          INR{" "}
          {calculateProductTotalBalance(
            item.sellingPriceWOT,
            item.quantity,
            item.discount,
            item.gst,
            item.amountPaid
          )}
        </SuiTypography>
      ),

      Product: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.productName}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item, "product")}
          viewHandler={() => viewHandler(item, "product")}
          deleteHandler={() =>
            deleteHandler(item.id, item.productName, item.productNo)
          }
          mailLink={item?.mailLink}
        />
      ),
    };
  });
  return rows_;
};
