import { Checkbox, FormControlLabel } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import CustomButton from "containers/CustomButton";
import React from "react";

function ClientT_C({
  termsAndConditions,
  isClientTermsAccepted,
  setIsClientTermsAccepted,
  loader,
  termsSubmitHandler,
  clientData,
}) {
  return (
    <SuiBox my={2}>
      <SuiBox
        style={{
          border: "1px solid #D2D6DA",
          borderRadius: 5,
        }}
        p={1.5}
      >
        <SuiTypography variant="h5">Terms & Conditions</SuiTypography>

        <SuiBox mt={1}>
          <SuiInput
            disabled
            type="text"
            name="T&C"
            multiline
            rows={7}
            placeholder="Enter T & C"
            disable={true}
            value={termsAndConditions ?? ""}
          />
        </SuiBox>

        {
          <SuiBox ml={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isClientTermsAccepted}
                  onChange={() => {
                    setIsClientTermsAccepted((pre) => !pre);
                  }}
                  disabled={clientData?.isClientTermsAccepted ? true : false}
                />
              }
              label="I have read and accept the terms and conditions."
              ml={1}
            />
          </SuiBox>
        }

        {!clientData?.isClientTermsAccepted && (
          <CustomButton
            color="dark"
            variant="gradient"
            onClick={termsSubmitHandler}
            fullWidth
            disabled={loader || !isClientTermsAccepted}
            loader={loader}
            title={"Submit"}
          />
        )}

        <SuiTypography variant="caption" style={{ display: "block" }} mt={1.5}>
          Please contact to your fellow nutrition/dietitians to change your
          terms & conditions
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

export default ClientT_C;
