import React, { useState, useEffect, useCallback, useRef } from "react";
import Card from "@mui/material/Card";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import Table from "examples/Tables/Table";
import ClientSearch from "./Searchbar";
import NoData from "containers/DataNotAvailBox";
import { ClientRows_ } from "./ClientRows.js";
import moment from "moment";
import Searchbar from "containers/Searchbar";
import * as XLSX from "xlsx";
import { timestampConversion } from "utils/common";
import { clearFilterState } from "store/features/clients";
import { useDispatch } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { sortData } from "utils/sort";

function ClientTable({
  clients,
  loader,
  services,
  filterState,
  addHandler,
  editHandler,
  deleteHandler,
  familyArray,
  filterFamilyMember,
  viewHandler,
}) {
  const [rows, setRows] = useState([]); // rows for client table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [clientArray, setclientArray] = useState([]); //
  let navigate = useNavigate(); //for navigation

  const [serviceOptions, setServiceOptions] = useState([]); //filtering wrt services
  const dispatch = useDispatch(); //for redux dispatch

  // sorting states
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
    sortKey: "",
  });
  // options for dropdown of service filter
  useEffect(() => {
    let serviceArray = services.map((item, index) => {
      return {
        value: item?.serviceName,
        label: item?.serviceName,
        key: index,
      };
    });
    setServiceOptions(serviceArray);
  }, [services]);

  const columns = [
    { name: "S.no", align: "left", width: "30px" },

    { name: "Date/Time", align: "left", sort: true, sortKey: "createdAt" },
    {
      name: "Client Name",
      align: "left",
      minWidth: "130px",
      sort: true,
      sortKey: "name",
    },
    {
      name: "Family Name",
      align: "left",
      minWidth: "130px",
      sort: true,
      sortKey: "familyName",
    },

    // { name: "Customer Id", align: "left", minWidth: "130px" },
    { name: "T&C Status", align: "left", minWidth: "130px" },

    {
      name: "Phone",
      align: "left",
      minWidth: "120px",
      sort: true,
      sortKey: "phoneNumber",
    },
    {
      name: "Email",
      align: "left",
      minWidth: "150px",
      sort: true,
      sortKey: "email",
    },
    // { name: "Address", align: "left", minWidth: '120px' },
    // { name: "Gender", align: "left", minWidth: '120px' },
    // { name: "DOB", align: "left" },
    // { name: "Anniversary", align: "left" },
    // { name: "Age", align: "left" },
    // { name: "Occupation", align: "left", minWidth: '160px' },
    // { name: "Referred By", align: "left", minWidth: '120px' },
    // { name: "Food preference", align: "left", minWidth: '160px' },
    // { name: "Lead From", align: "left", minWidth: '120px' },

    // { name: "Package", align: "left", minWidth: '120px' },
    // { name: "Total amount", align: "left", minWidth: '120px' },
    // { name: "Amount Paid", align: "left", minWidth: '120px' },
    // { name: "Discount", align: "left", minWidth: '120px' },

    // { name: "Balance", align: "left", minWidth: '120px' },
    // { name: "Payment Mode", align: "left", minWidth: '150px' },

    // { name: "Status", align: "left", minWidth: '120px' },
    { name: "Action", align: "left", minWidth: "110px" },
  ];

  useEffect(() => {
    const sortedRows = sortData(clientArray, sortConfig);

    setRows(
      ClientRows_(
        sortedRows,
        navigate,
        editHandler,
        deleteHandler,
        filterFamilyMember,
        viewHandler
      )
    );
  }, [
    clientArray,
    deleteHandler,
    editHandler,
    navigate,
    filterFamilyMember,
    sortConfig,
    viewHandler,
  ]);

  const handleSort = (name, sortKey) => {
    let direction = "asc";
    if (sortConfig.key === name && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: name, direction, sortKey });
  };

  const [filterQuery, setFilterQuery] = useState({
    status: null,
    package: null,
    familyName: null,
    fromDate: null,
    toDate: null,
  }); // for filtering

  //filtering on the basis of tile click handler
  const filterOnTileClick = useCallback(() => {
    let timestamp;

    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    setFilterQuery((e) => {
      e["fromDate"] = firstDayOfMonth;
      e["toDate"] = date;
      return e;
    });

    let filterData = clients.filter((item) => {
      timestamp = item.purchasedAt;
      timestamp = timestamp.seconds
        ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
        : timestamp;
      timestamp = moment.utc(timestamp);

      if (
        new Date(firstDayOfMonth) >=
        new Date(moment(timestamp).format("YYYY-MM-DD"))
      ) {
        return false;
      }

      if (moment.utc(moment(date).add(24, "hours")).isBefore(timestamp)) {
        return false;
      }

      return item;
    });

    if (searchVal) {
      let filteredProduct = filterData.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setclientArray((e) => filteredProduct);
    } else {
      setclientArray(filterData);
    }

    dispatch(clearFilterState());
  }, [dispatch, clients, searchVal]);

  const filterHandler = () => {
    setsearchVal("");
    let timestamp;
    let filterData = clients.filter((item) => {
      if (filterQuery.status) {
        if (filterQuery.status === "all") {
          setFilterQuery({ ...filterQuery, status: null });
        } else if (filterQuery.status !== item["status"]) {
          return false;
        }
      }

      if (filterQuery.service) {
        if (filterQuery.service === "all") {
          setFilterQuery({ ...filterQuery, service: null });
        } else if (filterQuery.service !== item["serviceName"]) {
          return false;
        }
      }

      if (filterQuery.familyName) {
        if (filterQuery.familyName === "all") {
          setFilterQuery({ ...filterQuery, familyName: null });
        } else if (filterQuery.familyName !== item["familyName"]) {
          return false;
        }
      }

      if (filterQuery.fromDate) {
        timestamp = item.createdAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);
        if (
          new Date(filterQuery.fromDate) >
          new Date(moment(timestamp).format("YYYY-MM-DD"))
        ) {
          return false;
        }
      }

      if (filterQuery.toDate) {
        timestamp = item.createdAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);
        if (
          moment
            .utc(moment(filterQuery.toDate).add(24, "hours"))
            .isBefore(timestamp)
        ) {
          return false;
        }
      }
      return item;
    });
    setclientArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      status: null,
      package: null,
      familyName: null,
      toDate: null,
      fromDate: null,
    });
    setclientArray(clients);
  }, [clients]);

  //use for filter according to tile
  useEffect(() => {
    if (filterState.filter && clients.length > 0) {
      if (filterState?.state === "SERVICE") {
        filterOnTileClick(() => {
          const element = document.getElementById("product");
          if (element) {
            //will scroll smoothly to the top of the product section
            element.scrollIntoView({ behavior: "smooth" });
          }
        });
      } else {
        resetHandler();
        setclientArray(clients);
      }
    }
  }, [
    clients,
    resetHandler,
    setsearchVal,
    filterOnTileClick,
    filterState.state,
    filterState.filter,
  ]);

  //for searching

  const redirectState = useRef(filterState.redirect);
  useEffect(() => {
    if (searchVal) {
      resetHandler();
      let filteredProduct = clients.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setclientArray((e) => filteredProduct);
    } else {
      if (!redirectState.current) {
        redirectState.current = false;
        resetHandler();
        setclientArray(clients);
      }
    }
  }, [searchVal, resetHandler, setclientArray, dispatch, clients]);

  const excelDownload = () => {
    let updatedData = clients.map((item, index) => {
      let time1 = item?.createdAt?.seconds
        ? moment(timestampConversion(item?.createdAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : moment(item?.createdAt).local().format("MMMM Do YYYY, h:mm a");
      return [
        index + 1,
        time1,
        item.name,
        item.familyName ?? "N/A",
        item.customerId ?? "N/A",
        item.phoneNumber,
        item.email,
        item.address,
        item.gender === null ? "N/A" : item.gender,
        item.dob,
        item.age,
        item.occupation,
        item.referredBy,
        item.preference === null ? "N/A" : item.preference,
        item.leadFrom === null ? "N/A" : item.leadFrom,
        item.serviceName,
        item.totalAmount,
        item.amountPaid,
        item.discount,
        item.totalAmount - (item.amountPaid + item.discount),
        item.paymentMode,
        item.status,
      ];
    });
    let excelData = [
      [
        "id",
        "DATE/TIME",
        "CLIENT NAME",
        "FAMILY NAME",
        "CUSTOMER ID",
        "PHONE",
        "EMAIL",
        "ADDRESS",
        "GENDER",
        "DOB",
        "AGE",
        "OCCUPATION",
        "REFERRED BY",
        "FOOD PREFERENCE",
        "LEAD FROM",
        "PACKAGE",
        "TOTAL AMOUNT",
        "AMOUNT PAID",
        "DISCOUNT",
        "BALANCE",
        "PAYMENT MODE",
        "STATUS",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Client_List_Consultant.xlsx");
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
          flexDirection={{ xs: "column-reverse", lg: "row" }}
          gap={3}
        >
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={"dark"}
            sx={{ mb: 1 }}
          >
            Below are the list of clients who have taken consultation from you.
          </SuiTypography>

          <SuiBox
            className="search-box-div"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: { sm: "flex-end" },
            }}
          >
            <Searchbar
              placeholder={"Search Client Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />

            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={addHandler}
              width={180}
              style={{
                marginRight: 25,
                marginBottom: 10,
                height: 40,
                width: 230,
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Add Client
            </SuiButton>
          </SuiBox>
        </SuiBox>

        <ClientSearch
          serviceOptions={serviceOptions}
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          excelDownload={excelDownload}
          familyArray={familyArray}
        />
        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table
              columns={columns}
              rows={rows}
              handleSort={handleSort}
              sortConfig={sortConfig}
            />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default ClientTable;
