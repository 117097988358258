import React, { useState, useEffect } from "react";
import moment from "moment";
import { timestampConversion } from "utils/common";
import { measurementRecords } from "containers/clientDetails/sub-components/common/measurementRecords";
import {
  measurementsTableColumns,
  basicMeasurementKeys,
} from "utils/tablesHeaderContent";
import DataVisualizer from "containers/clientDetails/sub-components/DataVisualizer";
import MeasurementModal from "containers/clientDetails/sub-components/modal/measurement";
import DefaultModal from "containers/clientDetails/sub-components/modalContainer/default";
import { useCallback } from "react";
import { headerDesc } from "utils/helper";
import * as XLSX from "xlsx";

function MeasurementDataVisualizer({
  id,
  measurements,
  title,
  setSelectedClient,
  deleteHandler,
}) {
  const [rows, setRows] = useState([]);
  const [chartSelected, setChartSelected] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [newMeasurementModal, setNewMeasurementModal] = useState(false);
  const columns = [...measurementsTableColumns];
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState("view");

  useEffect(() => {
    let measurementObj = {};
    const labelArray = [...basicMeasurementKeys];
    labelArray.forEach((item) => {
      measurementObj[item.value] = {
        label: item.value,
        color: item.color,
        data: [],
      };
    });

    let labels = [];
    let measurementArray = measurements.slice();
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    measurementArray.sort(function (a, b) {
      const createdAt1 = convertTimestamp(a?.timestamp);
      const createdAt2 = convertTimestamp(b?.timestamp);
      return createdAt1 - createdAt2;
    });

    measurementArray.forEach((item) => {
      const timestamp = convertTimestamp(item.timestamp);
      const formattedTimestamp = moment.utc(timestamp).format("DD/MM/YYYY");
      labels.push(formattedTimestamp);

      labelArray.forEach((label) => {
        measurementObj[label.value]["data"].push(
          parseFloat(item[label.value] ? item[label.value] : 0)
        );

        return label;
      });
    });

    setChartData({
      labels: labels,
      datasets: Object.keys(measurementObj).map((key) => {
        return {
          label: key,
          color: measurementObj[key]["color"],
          data: measurementObj[key]["data"],
        };
      }),
    });
  }, [measurements]);

  const addHandler = useCallback(() => {
    setNewMeasurementModal(true);
    setMode("create");
  }, []);

  const editHandler = useCallback((item) => {
    setSelectedItem(item);
    setNewMeasurementModal(true);
    setMode("edit");
  }, []);

  const viewHandler = useCallback((item) => {
    setSelectedItem(item);
    setNewMeasurementModal(true);
    setMode("view");
  }, []);

  useEffect(() => {
    // Sort vitals array by createdAt
    const sortedData = measurements?.sort((a, b) => {
      const timestampA = a?.createdAt.seconds
        ? a?.createdAt
        : { seconds: a?.createdAt.getTime() / 1000, nanoseconds: 0 };
      const timestampB = b?.createdAt.seconds
        ? b?.createdAt
        : { seconds: b?.createdAt.getTime() / 1000, nanoseconds: 0 };

      return timestampB.seconds - timestampA.seconds;
    });
    setRows(
      measurementRecords(sortedData, editHandler, viewHandler, deleteHandler)
    );
  }, [measurements, editHandler, viewHandler]);

  const downloadBtnClickHandler = () => {
    let updatedData = measurements.map((item, index) => {
      let createdAt = item?.createdAt?.seconds
        ? moment(timestampConversion(item?.createdAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : "N/A";

      let updatedAt = item?.updatedAt?.seconds
        ? moment(timestampConversion(item?.updatedAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : "N/A";

      if (index === 0) {
        console.log({ item });
      }

      // let updatedAt = item?.updatedAt
      //   ? moment(item?.updatedAt).local().format("MMMM Do YYYY, h:mm a")
      //   : "N/A";

      // Combine value and unit for each metric
      let tummy =
        item?.metrics?.tummy?.value && item?.metrics?.tummy?.unit
          ? `${item?.metrics?.tummy?.value} ${item?.metrics?.tummy?.unit}`
          : "N/A";
      let chest =
        item?.metrics?.chest?.value && item?.metrics?.chest?.unit
          ? `${item?.metrics?.chest?.value} ${item?.metrics?.chest?.unit}`
          : "N/A";
      let height =
        item?.metrics?.height?.value && item?.metrics?.height?.unit
          ? `${item?.metrics?.height?.value} ${item?.metrics?.height?.unit}`
          : "N/A";
      let hip =
        item?.metrics?.hip?.value && item?.metrics?.hip?.unit
          ? `${item?.metrics?.hip?.value} ${item?.metrics?.hip?.unit}`
          : "N/A";
      let thigh =
        item?.metrics?.thigh?.value && item?.metrics?.thigh?.unit
          ? `${item?.metrics?.thigh?.value} ${item?.metrics?.thigh?.unit}`
          : "N/A";
      let arm =
        item?.metrics?.arm?.value && item?.metrics?.arm?.unit
          ? `${item?.metrics?.arm?.value} ${item?.metrics?.arm?.unit}`
          : "N/A";
      let waist =
        item?.metrics?.waist?.value && item?.metrics?.waist?.unit
          ? `${item?.metrics?.waist?.value} ${item?.metrics?.waist?.unit}`
          : "N/A";
      let neck =
        item?.metrics?.neck?.value && item?.metrics?.neck?.unit
          ? `${item?.metrics?.neck?.value} ${item?.metrics?.neck?.unit}`
          : "N/A";

      return [
        index + 1,
        createdAt,
        updatedAt,
        item?.weight ?? "N/A",
        item?.height ?? "N/A",
        chest,
        hip,
        thigh,
        arm,
        waist,
        neck,
        tummy,
        item?.clientNotes ?? "N/A",
        item?.consultantionNotes ?? "N/A",
      ];
    });

    let excelData = [
      [
        "ID",
        "Created At",
        "Updated At",
        "Weight",
        "Height",
        "Chest",
        "Hip",
        "Thigh",
        "Arm",
        "Waist",
        "Neck",
        "Tummy",
        "Client Notes",
        "Consultation Notes",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Measurements.xlsx");
  };

  return (
    <>
      <DataVisualizer
        rows={rows}
        columns={columns}
        setChartSelected={setChartSelected}
        chartTitle={"Measurements"}
        chartData={chartData}
        chartDescription={"Your measurement comparison at every instance"}
        chartSelected={chartSelected}
        addHandler={addHandler}
        title={title}
        downloadButton
        downloadBtnClickHandler={downloadBtnClickHandler}
      />
      <DefaultModal
        open={newMeasurementModal}
        {...headerDesc(mode, "Measurement details", selectedItem)}
        handleCloseModal={() => {
          setNewMeasurementModal(false);
          setSelectedItem(null);
        }}
        mode={mode}
        setMode={setMode}
      >
        <MeasurementModal
          id={id}
          persona="admin"
          mode={mode}
          open={newMeasurementModal}
          setSelectedClient={setSelectedClient}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleCloseModal={() => {
            setNewMeasurementModal(false);
            setSelectedItem(null);
          }}
        />
      </DefaultModal>
    </>
  );
}

export default MeasurementDataVisualizer;
