import React from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import { style } from "./style.js";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput/index.js";
import { updateFirstLetter } from "utils/helper.js";

function ConfirmationModal({
  isFetching,
  title,
  isInput,
  formdata,
  setFormdata,
  inputName,
  onCancelHandler,
  onConfirmHandler,
}) {
  return (
    <SuiBox style={style.base}>
      <SuiBox mt={3} style={style.baseModal}>
        <SuiBox mr={2} style={style.box}>
          <SuiTypography variant="h6">{title}</SuiTypography>
        </SuiBox>

        {isInput && (
          <SuiBox mr={2} mb={2} width="100%">
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                {updateFirstLetter(inputName)}
              </SuiTypography>
              <SuiInput
                type="text"
                name={inputName}
                value={formdata?.[inputName] ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </SuiBox>
        )}

        <SuiBox
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            disabled={isFetching}
            onClick={onConfirmHandler}
          >
            {isFetching ? "Confirm ..." : "Confirm"}
          </SuiButton>

          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            disabled={isFetching}
            onClick={onCancelHandler}
          >
            Cancel
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default ConfirmationModal;
