import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import { globaleDateTimeFormatter } from "utils/helper";
import TableAction from "containers/table-action";

const calculateBalance = (totalAmount, discount, amountPaid) => {
  if (totalAmount) {
    return (
      parseFloat(totalAmount) -
      parseFloat(discount ?? 0) -
      parseFloat(amountPaid ?? 0)
    ).toFixed(2);
  }
};

const reOpenModal = (familyName, filterFamilyMember) => {
  if (familyName !== undefined) {
    filterFamilyMember(familyName);
  }
};

export const ClientRows_ = (
  clientArray,
  navigate,
  editHandler,
  deleteHandler,
  filterFamilyMember,
  viewHandler
) => {
  const rows_ = clientArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.purchasedAt)}
        </SuiTypography>
      ),

      "Client Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: "#17C1E8",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/clients/${item.id}`);
          }}
        >
          {item?.name}
        </SuiTypography>
      ),

      "Family Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={
            item?.familyName
              ? {
                  color: "#17C1E8",
                  cursor: "pointer",
                }
              : {}
          }
          onClick={() => {
            reOpenModal(item?.familyName, filterFamilyMember);
          }}
        >
          {item.familyName ?? "N/A"}
        </SuiTypography>
      ),

      // "Customer Id": (
      //   <SuiTypography variant="button" fontWeight="medium">
      //     {item.customerId ?? "N/A"}
      //   </SuiTypography>
      // ),
      "T&C Status": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: item.isClientTermsAccepted ? "#2ecc71" : "#FF0000" }}
        >
          {item.isClientTermsAccepted ? "Accepted" : "Not Accepted"}
        </SuiTypography>
      ),

      Phone: (
        <>
          <SuiTypography variant="button" fontWeight="medium">
            {item?.phoneNumber}
          </SuiTypography>
          <IconButton
            size="small"
            style={{ padding: "3px", marginLeft: "5px" }}
            onClick={() => {
              window.open(`https://wa.me/${item?.phoneNumber}`, "_blank");
            }}
          >
            <WhatsApp style={{ color: "#075e54" }} />
          </IconButton>
        </>
      ),

      Email: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.email}
        </SuiTypography>
      ),

      Address: (
        <Tooltip title={item?.address ?? ""} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.address?.length > 20
              ? `${item?.address?.slice(0, 20)}...`
              : item?.address}
          </SuiTypography>
        </Tooltip>
      ),

      Gender: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.gender ?? "N/A"}
        </SuiTypography>
      ),

      DOB: (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.dob, "date") ?? "N/A"}
        </SuiTypography>
      ),

      Anniversary: (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.anniversary, "date") ?? "N/A"}
        </SuiTypography>
      ),

      "Referred By": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.referredBy}
        </SuiTypography>
      ),

      "Food preference": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.preference ?? "N/A"}
        </SuiTypography>
      ),

      "Lead From": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.leadFrom ?? "N/A"}
        </SuiTypography>
      ),

      "Commencement Date": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.startDate)}
        </SuiTypography>
      ),

      Package: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.serviceName}
        </SuiTypography>
      ),

      "Completion Date": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.completionDate)}
        </SuiTypography>
      ),

      "Total amount": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.totalAmount}
        </SuiTypography>
      ),

      "Amount Paid": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.amountPaid ?? 0}
        </SuiTypography>
      ),

      Discount: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.discount}
        </SuiTypography>
      ),

      Balance: (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: "red" }}
        >
          INR{" "}
          {calculateBalance(item.totalAmount, item.discount, item.amountPaid)}
        </SuiTypography>
      ),

      "Payment Mode": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.paymentMode ?? "N/A"}
        </SuiTypography>
      ),

      "Complementary Service": (
        <Tooltip title={item?.complementaryService ?? ""} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.complementaryService?.length > 20
              ? `${item?.complementaryService?.slice(0, 20)}...` ?? "N/A"
              : item?.complementaryService ?? "N/A"}
          </SuiTypography>
        </Tooltip>
      ),

      "Service Type": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.serviceInstance ?? "N/A"}
        </SuiTypography>
      ),

      Status: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.status ?? "N/A"}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item, "service")}
          viewHandler={() => viewHandler(item, "service")}
          deleteHandler={() =>
            deleteHandler(item.id, item.serviceName, item.serviceNo)
          }
          mailLink={item?.mailLink}
        />
      ),
    };
  });
  return rows_;
};
