export const style = {
  base: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    zIndex: 10000,
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0,0,0,0.4)",
  },

  baseModal: {
    display: "flex",
    boxSizing: "border-box",
    width: 522,
    padding: 30,
    flexDirection: "column",
    background: "white",
    border: "1px solid #D2D6DA",
    borderRadius: 8,
    top: 20,
    left: -100,
    zIndex: 1000,
  },

  box: {
    display: "flex",
    marginBottom: 10,
  },
};
