import React, { useState, useEffect } from "react";
import { vitalRows } from "../../sub-components/common/vitalRows";
import moment from "moment";
import { timestampConversion } from "utils/common";
import { basicVitalKeys } from "utils/tablesHeaderContent";
import { vitalsTableColumns } from "utils/tablesHeaderContent";
import DataVisualizer from "containers/clientDetails/sub-components/DataVisualizer";
import VitalModal from "containers/clientDetails/sub-components/modal/vital";
import DefaultModal from "containers/clientDetails/sub-components/modalContainer/default";
import { useCallback } from "react";
import { headerDesc } from "utils/helper";
import * as XLSX from "xlsx";

function VitalDataVisualizer({
  id,
  setSelectedClient,
  vitals,
  title,
  deleteHandler,
}) {
  const data = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  };
  const [rows, setRows] = useState([]);
  const [chartData, setChartData] = useState(data);
  const [chartSelected, setChartSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newVitalModal, setNewVitalModal] = useState(false);
  const columns = [...vitalsTableColumns];
  const [mode, setMode] = useState("create");

  const addHandler = useCallback(() => {
    setNewVitalModal(true);
    setMode("create");
    setSelectedItem(null);
  }, []);

  const editHandler = useCallback((item) => {
    setSelectedItem(item);
    setNewVitalModal(true);
    setMode("edit");
  }, []);

  const viewHandler = useCallback((item) => {
    setSelectedItem(item);
    setNewVitalModal(true);
    setMode("view");
  }, []);

  useEffect(() => {
    // Sort vitals array by createdAt
    const sortedVitals = vitals.slice().sort((a, b) => {
      const timestampA = a?.createdAt.seconds
        ? a?.createdAt
        : { seconds: a?.createdAt.getTime() / 1000, nanoseconds: 0 };
      const timestampB = b?.createdAt.seconds
        ? b?.createdAt
        : { seconds: b?.createdAt.getTime() / 1000, nanoseconds: 0 };

      return timestampB.seconds - timestampA.seconds;
    });

    setRows(vitalRows(sortedVitals, editHandler, viewHandler, deleteHandler));
  }, [vitals, editHandler, viewHandler]);

  useEffect(() => {
    let vitalObj = {};

    const labelArray = [...basicVitalKeys];

    labelArray.forEach((item) => {
      vitalObj[item.value] = {
        label: item.value,
        color: item.color,
        data: [],
      };
    });

    let labels = [];

    let vitalArray = vitals.slice();

    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    vitalArray.sort(function (a, b) {
      const createdAt1 = convertTimestamp(a?.timestamp);
      const createdAt2 = convertTimestamp(b?.timestamp);
      return createdAt1 - createdAt2;
    });

    vitalArray.forEach((item) => {
      const timestamp = convertTimestamp(item.timestamp);
      const formattedTimestamp = moment.utc(timestamp).format("DD/MM/YYYY");
      labels.push(formattedTimestamp);

      labelArray.forEach((label) => {
        if (item[label.value]) {
          vitalObj[label.value]["data"].push(
            parseFloat(item[label.value] ?? 0)
          );
        }
      });
    });

    setChartData({
      labels: labels,
      datasets: Object.keys(vitalObj).map((key) => {
        return {
          label: key,
          color: vitalObj[key]["color"],
          data: vitalObj[key]["data"],
        };
      }),
    });
  }, [vitals]);

  const downloadBtnClickHandler = () => {
    let updatedData = vitals.map((item, index) => {
      // Convert timestamps to readable format
      let createdAt = item?.createdAt
        ? moment(item?.createdAt).local().format("MMMM Do YYYY, h:mm a")
        : "N/A";

      let timestamp = item?.timestamp
        ? moment(item?.timestamp).local().format("MMMM Do YYYY, h:mm a")
        : "N/A";

      return [
        index + 1,
        createdAt, // Created At Timestamp
        timestamp, // Timestamp
        item?.fat ?? "N/A", // Fat
        item?.vfat ?? "N/A", // Vfat
        item?.muscleMass ?? "N/A", // Muscle Mass
        item?.bodyAge ?? "N/A", // Body Age
        item?.rm ?? "N/A", // RM
        item?.bmi ?? "N/A", // BMI
        item?.consultantionNotes ?? "N/A", // Consultant Notes
        item?.clientNotes ?? "N/A", // Client Notes
      ];
    });

    let excelData = [
      [
        "ID",
        "Created At",
        "Updated At",
        "Fat",
        "Vfat",
        "Muscle Mass",
        "Body Age",
        "RM",
        "BMI",
        "Consultation Notes",
        "Client Notes",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Vitals.xlsx");
  };

  return (
    <>
      <DataVisualizer
        rows={rows}
        columns={columns}
        setChartSelected={setChartSelected}
        chartTitle={"Vitals"}
        chartData={chartData}
        chartDescription={"Your vital comparison at every instance"}
        chartSelected={chartSelected}
        addHandler={addHandler}
        title={title}
        downloadButton
        downloadBtnClickHandler={downloadBtnClickHandler}
      />
      <DefaultModal
        open={newVitalModal}
        {...headerDesc(mode, "Vital details", selectedItem)}
        handleCloseModal={() => {
          setNewVitalModal(false);
          setSelectedItem(null);
        }}
        mode={mode}
        setMode={setMode}
      >
        <VitalModal
          id={id}
          persona="admin"
          mode={mode}
          open={newVitalModal}
          setSelectedClient={setSelectedClient}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleCloseModal={() => {
            setNewVitalModal(false);
            setSelectedItem(null);
          }}
        />
      </DefaultModal>
    </>
  );
}

export default VitalDataVisualizer;
